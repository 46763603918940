import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { format } from 'date-fns'
import { LoaderIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import BackButton from '@/components/Buttons/BackButton'
import { DataTable } from '@/components/DataTable'
import FailureCodeComponent from '@/components/FailureCode'
import ProtectedComponent from '@/components/ProtectedComponent'
import UserActions from '@/components/UserDetails/Actions'
import UserLimits from '@/components/UserDetails/Limits'
import { Section, SectionOption } from '@/components/UserDetails/Section'
import UserUpdate from '@/components/UserDetails/Update'

import { IUser, KycStatusEnum } from '@/types/account/user.interface'

import { parseLimits } from '@/utils/parseLimits'
import { returnKycIcon } from '@/utils/return-kyc-icon'

import { fundingsColumns } from './transactions/exchange-fundings.columns'
import { tradesColumns } from './transactions/exchange-trades.columns'
import { transfersColumns } from './transactions/exchange-transfers.columns'
import { lightningColumns } from './transactions/starter-lightning.columns'
import { onchainColumns } from './transactions/starter-onchain.columns'
import api from '@/api'
import { cn, formatNumber, millisatoshisToBTC } from '@/utils'

function UserDetails() {
  const { userId } = useParams<{ userId: string }>()

  const { data, error, isLoading } = useQuery({
    queryKey: ['user-details', userId],
    queryFn: async () => {
      const resp = await api.get<IUser>(`/admin/users/${userId}/details`)

      return resp.data
    },
    refetchOnWindowFocus: false
  })

  const [selectedTab, setSelectedTab] = useState<
    'details' | 'stats' | 'actions' | 'limits' | 'transactions'
  >('details')

  const isVerified =
    data &&
    (data.kyc_status === KycStatusEnum.VERIFIED ||
      data.kyc_status === KycStatusEnum.VERIFIED_NO_SSN)
      ? true
      : false

  const accountDetails = useMemo(() => {
    if (!data) return []

    const details: SectionOption[] = [
      {
        label: 'ID',
        rightContent: (
          <p className='long-text whitespace-nowrap text-sm font-semibold'>
            {data.id}
          </p>
        )
      },
      {
        label: 'Display Name',
        rightContent: data.display_name || '-'
      },
      { label: 'Username', rightContent: `@${data.username}` },
      { label: 'Email', rightContent: data.email },
      {
        label: 'Membership Type',
        rightContent:
          data.membership_type === 'REGULAR' ? 'Regular' : 'Satoshi Plus'
      },
      {
        label: 'App Version',
        rightContent: data.app_version || '-'
      },
      {
        label: 'Device Type',
        rightContent: data.device_type || '-'
      },
      {
        label: 'Date Joined',
        rightContent: format(data.created_at, 'yyyy.MM.dd')
      },
      {
        label: 'KYC Status',
        rightContent: (
          <p
            className={cn(
              'flex items-center gap-1 text-sm font-semibold text-lime500',
              {
                'text-red500': data.kyc_status === KycStatusEnum.FAILED,
                'text-orange500': data.kyc_status === KycStatusEnum.STARTED,
                'text-foreground/50':
                  data.kyc_status === KycStatusEnum.UNVERIFIED
              }
            )}
          >
            {returnKycIcon(data.kyc_status, 'size-5')}
            {data.kyc_status}
          </p>
        )
      }
    ]
    if (
      data.kyc_status === KycStatusEnum.FAILED ||
      (data.kyc_failure_codes && data.kyc_failure_codes.length > 0)
    ) {
      details.push({
        label: 'KYC Reject Reason',
        rightContent:
          data.kyc_failure_codes && data.kyc_failure_codes.length > 0 ? (
            <div className='flex w-full max-w-[250px] flex-col gap-0.5 text-right'>
              {data.kyc_failure_codes.map(val => (
                <FailureCodeComponent key={val} failureCode={val} />
              ))}
            </div>
          ) : (
            '-'
          )
      })
    }

    if (
      data.kyc_status === KycStatusEnum.VERIFIED ||
      data.kyc_status === KycStatusEnum.VERIFIED_NO_SSN
    ) {
      details.push(
        {
          label: 'Legal Name',
          rightContent:
            data.first_name || data.last_name
              ? `${data.first_name} ${data.last_name}`
              : '-'
        },
        {
          label: 'State',
          rightContent: data.address_subdivision || '-'
        },
        {
          label: 'Country',
          rightContent: data.address_country_code || '-'
        }
      )
    }

    return details
  }, [data])

  const starterStats = useMemo(() => {
    if (!data) return []
    return [
      {
        label: 'BTC Balance',
        rightContent: `${millisatoshisToBTC(BigNumber(data.starter_stats.balance_msats || 0)).toFormat(8)} BTC`
      },
      {
        label: 'Total Send',
        rightContent: `${millisatoshisToBTC(BigNumber(data.starter_stats.total_sent_msats || 0)).toFormat(8)} BTC`
      },
      {
        label: 'Total Recieved',
        rightContent: `${millisatoshisToBTC(BigNumber(data.starter_stats.total_received_msats || 0)).toFormat(8)} BTC`
      }
    ]
  }, [data])

  const exchangeStats = useMemo(() => {
    if (!data) return []

    if (isVerified) {
      return [
        {
          label: 'BTC Balance',
          rightContent: `${millisatoshisToBTC(BigNumber(data.exchange_stats.balance_btc || 0)).toFormat(8)} BTC`
        },
        {
          label: 'USD Balance',
          rightContent: `$${formatNumber(data.exchange_stats.balance_usd, '0.00')}`
        },
        {
          label: 'Total Sent',
          rightContent: `$${formatNumber(data.exchange_stats.total_send_usd, '0.00')}`
        },
        {
          label: 'Total Recieved',
          rightContent: `$${formatNumber(data.exchange_stats.total_received_usd, '0.00')}`
        },
        {
          label: 'Total Bought',
          rightContent: `${millisatoshisToBTC(BigNumber(data.exchange_stats.total_bought_msats || 0)).toFormat(8)} BTC`
        },
        {
          label: 'Total Sold',
          rightContent: `${millisatoshisToBTC(BigNumber(data.exchange_stats.total_sold_msats || 0)).toFormat(8)} BTC`
        }
      ]
    }

    return null
  }, [data])

  return (
    <ProtectedComponent isPage permissionSlug='users_view'>
      <Helmet>
        <title>STBA Admin - User Details</title>
      </Helmet>
      <section
        className={cn(
          'flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10',
          {
            '!pt-4 lg:pt-6': data?.avatar
          }
        )}
      >
        <section
          className={cn(
            'flex w-full flex-col gap-1 border-b border-white/15 pb-2 md:gap-2 md:pb-4 lg:gap-4'
          )}
        >
          <BackButton />
          <div className='flex flex-row items-center gap-2'>
            {data?.avatar && (
              <img
                src={data.avatar}
                alt=''
                className='size-16 rounded-full bg-gray900'
              />
            )}
            <div className='flex flex-col gap-1'>
              <p className='text-lg font-bold'>{data?.display_name}</p>
              <p className='text-foreground/50'>@{data?.username}</p>
            </div>
          </div>
        </section>
        <section className='flex flex-wrap gap-2 lg:gap-4'>
          {['details', 'stats', 'actions', 'limits', 'transactions'].map(
            tab => (
              <button
                key={tab}
                onClick={() =>
                  setSelectedTab(
                    tab as
                      | 'details'
                      | 'stats'
                      | 'actions'
                      | 'limits'
                      | 'transactions'
                  )
                }
                className={cn(
                  'select-none text-xl font-bold lg:text-2xl xl:text-3xl',
                  {
                    'text-orange550': tab === selectedTab,
                    'text-white text-opacity-50 hover:text-opacity-80 active:text-opacity-100':
                      !(tab === selectedTab)
                  }
                )}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            )
          )}
        </section>
        {isLoading ? (
          <LoaderIcon className='mx-auto my-10 size-10' />
        ) : error || !data ? (
          <p>
            Error loading user details{' '}
            {error && (
              <>
                <br />
                {error.message}
              </>
            )}
          </p>
        ) : (
          <section
            className={cn({
              'grid w-full grid-cols-1 gap-2 px-2 lg:grid-cols-2 lg:gap-8 lg:px-0 xl:grid-cols-3':
                selectedTab !== 'transactions',
              'flex w-full flex-col gap-2 md:gap-4':
                selectedTab === 'transactions'
            })}
          >
            {selectedTab === 'details' && (
              <div className='flex flex-col gap-2'>
                <Section options={accountDetails} />
                <UserUpdate initial={data} />
              </div>
            )}
            {selectedTab === 'stats' && (
              <>
                {exchangeStats !== null ? (
                  <>
                    <Section title='Exchange' options={exchangeStats} />
                    <Section title='Starter' options={starterStats} />
                  </>
                ) : (
                  <Section title='Starter' options={starterStats} />
                )}
              </>
            )}
            {selectedTab === 'actions' && (
              <>
                {data.ip_addresses && data.ip_addresses.length > 0 && (
                  <Section
                    title='IP Addresses'
                    options={[
                      {
                        label: 'All IP Addresses Blocked',
                        rightContent: data.is_all_ip_addresses_blocked
                          ? 'Yes'
                          : 'No'
                      },
                      {
                        label: 'IP Addresses',
                        selfStart: true,
                        rightContent: (
                          <div className='flex flex-col items-end gap-1'>
                            {data.ip_addresses.map((ip, index) => (
                              <p
                                key={index}
                                className={cn(
                                  'text-[14px] font-semibold text-green-500',
                                  {
                                    'text-red500': ip.is_blocked
                                  }
                                )}
                              >
                                {ip.value}
                              </p>
                            ))}
                          </div>
                        )
                      }
                    ]}
                  />
                )}
                <UserActions user={data} noTitle />
              </>
            )}
            {selectedTab === 'limits' && (
              <UserLimits
                limits={parseLimits(data.limits)}
                hasExchange={isVerified}
              />
            )}
            {data && selectedTab === 'transactions' && (
              <>
                <DataTable
                  columns={lightningColumns}
                  data={data.transactions.starter.lightning}
                  url='lightning'
                  title='Starter Lightning'
                  totalText='latest lightning transactions'
                  tableClassName='!max-h-[400px]'
                />
                <DataTable
                  columns={onchainColumns}
                  data={data.transactions.starter.onchain}
                  url='onchain'
                  title='Starter Onchain'
                  totalText='latest onchain transactions'
                  tableClassName='!max-h-[400px]'
                />
                {isVerified ? (
                  <>
                    <DataTable
                      columns={fundingsColumns}
                      data={data.transactions.exchange.fundings}
                      url='fundings'
                      title='Exchange Fundings'
                      totalText='latest fundings'
                      tableClassName='!max-h-[400px]'
                    />
                    <DataTable
                      columns={transfersColumns}
                      data={data.transactions.exchange.transfers}
                      url='transfers'
                      title='Exchange Transfers'
                      totalText='latest transfers'
                      tableClassName='!max-h-[400px]'
                    />
                    <DataTable
                      columns={tradesColumns}
                      data={data.transactions.exchange.trades}
                      url='trades'
                      title='Exchange Trades'
                      totalText='latest trades'
                      tableClassName='!max-h-[400px]'
                    />
                  </>
                ) : null}
              </>
            )}
          </section>
        )}
      </section>
    </ProtectedComponent>
  )
}

export default UserDetails
