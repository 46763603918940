export default function Blocked({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
    >
      <g
        id='Group_4138'
        data-name='Group 4138'
        transform='translate(-1250 -835)'
      >
        <rect
          id='Rectangle_7518'
          data-name='Rectangle 7518'
          width='20'
          height='20'
          rx='10'
          transform='translate(1250 835)'
          fill='#8f55fe'
        />
        <g
          id='Group_4137'
          data-name='Group 4137'
          transform='translate(884.192 788.506)'
        >
          <rect
            id='Rectangle_2283'
            data-name='Rectangle 2283'
            width='2.08'
            height='12.062'
            rx='1.04'
            transform='translate(372.268 50.463)'
            fill='#fff'
          />
          <rect
            id='Rectangle_2284'
            data-name='Rectangle 2284'
            width='2.08'
            height='12.062'
            rx='1.04'
            transform='translate(377.268 50.463)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}
