import { useEffect, useState } from 'react'

import { TabItem, Tabs } from '@/components/Tabs'

import { SectionItem } from '@/components/InfoSection/SectionItem/index'

interface TabsSectionItemProps {
  description?: string
  tabs: TabItem[]
  className?: string
  labelClassName?: string
  loading?: boolean
  error?: string
}

export const TabsSectionItem = ({
  error,
  loading,
  labelClassName,
  tabs,
  description,
  className
}: TabsSectionItemProps) => {
  const [selectedTab, setSelectedTab] = useState<TabItem | undefined>(tabs[0])

  useEffect(() => {
    setSelectedTab(tabs[0])
  }, [tabs])

  return (
    <SectionItem
      error={error}
      loading={loading}
      className={className}
      labelClassName={labelClassName}
      label={selectedTab?.value || ''}
      description={
        <div className='flex flex-col items-center gap-[20px]'>
          <p className='text-center text-[20px] font-medium leading-[30px] text-white/50'>
            {description}
          </p>
          <Tabs
            tabs={tabs}
            onTab={setSelectedTab}
            selectedTabKey={selectedTab?.key}
          />
        </div>
      }
    />
  )
}
