import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'
import ProtectedComponent from '@/components/ProtectedComponent'

import { IUsernameBlacklist } from '@/types/username-blacklist/username-blacklist.interface'

import formatId from '@/utils/formatId'

import CreateOrUpdate from './CreateOrUpdate'

export const columns: ColumnDef<IUsernameBlacklist>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.username}`)
            toast.success('Username Copied to clipboard')
          }}
        >
          @{row.original.username}
        </button>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='opacity-50'>-</p>
      }

      return <p className='opacity-50'>{format(date, 'yyyy.MM.dd')}</p>
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ProtectedComponent permissionSlug='username_blacklist_update'>
            <CreateOrUpdate initial={row.original} />
          </ProtectedComponent>
        </div>
      )
    },
    enableSorting: false
  }
]
