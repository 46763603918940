import { ColumnDef } from '@tanstack/react-table'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'
import ProtectedComponent from '@/components/ProtectedComponent'

import { IConfiguration } from '@/types/configurations/configuration.interface'

import CreateOrUpdateConfiguration from './CreateOrUpdateConfiguration'
import DeleteConfiguration from './Delete'

export const columns: ColumnDef<IConfiguration>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => row.original.name
  },
  {
    accessorKey: 'description',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Description' />
    },
    cell: ({ row }) => {
      if (row.original.description) {
        return <p>{row.original.description}</p>
      } else {
        return <p className='text-foreground/50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'value',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Value' />
    },
    cell: ({ row }) => {
      if (row.original.name) {
        return <p className='text-foreground/50'>{row.original.value}</p>
      } else {
        return <p className='text-foreground/50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ProtectedComponent permissionSlug='configurations_update'>
            <CreateOrUpdateConfiguration initial={row.original} />
          </ProtectedComponent>
          <ProtectedComponent permissionSlug='configurations_update'>
            <DeleteConfiguration id={row.original.id} />
          </ProtectedComponent>
        </div>
      )
    },
    enableSorting: false
  }
]
