import { EarnDataInterface } from '@/types/metrics/metrics.interface'

import { EMPTY_NUMBER, formatInteger, formatNumber, msatsToSats } from '@/utils'

export const formatEarnData = (inputData: EarnDataInterface | undefined) => {
  const data = inputData?.result

  return {
    earnWalletBalance: {
      label: `$${formatNumber(data?.total_balance_usd, EMPTY_NUMBER)}`,
      description: `${formatInteger(String(msatsToSats(data?.total_balance_msats, EMPTY_NUMBER)), EMPTY_NUMBER)} sats`,
      subDescription: 'Earn Wallet Balance'
    },
    totalFuturePayout: {
      label: `$${formatNumber(data?.total_future_payout_usd, EMPTY_NUMBER)}`,
      description: `${formatInteger(
        String(msatsToSats(data?.total_future_payout_msats, EMPTY_NUMBER)),
        EMPTY_NUMBER
      )} sats`,
      subDescription: 'Total Future Payout'
    },
    totalDailyEarnRate: {
      label: `$${formatNumber(data?.total_daily_earn_rate_usd, EMPTY_NUMBER)}`,
      description: `${formatInteger(
        String(msatsToSats(data?.total_daily_earn_rate_msats, EMPTY_NUMBER)),
        EMPTY_NUMBER
      )} sats`,
      subDescription: 'Total Daily Earn Rate'
    },
    totalPaidOut: {
      label: `$${formatNumber(data?.total_paid_out_usd, EMPTY_NUMBER)}`,
      description: `${formatInteger(String(msatsToSats(data?.total_paid_out_msats, EMPTY_NUMBER)), EMPTY_NUMBER)} sats`,
      subDescription: 'Total Paid Out'
    },
    totalUnclaimed: {
      label: `$${formatNumber(data?.total_earned_unclaimed_usd, EMPTY_NUMBER)}`,
      description: `${formatInteger(
        String(msatsToSats(data?.total_earned_unclaimed_msats, EMPTY_NUMBER)),
        EMPTY_NUMBER
      )} sats`,
      subDescription: 'Total Claimable'
    }
  }
}
