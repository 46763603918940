import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import api from '@/api'
import Autocomplete from '@/components/Autocomplete'
import { ResultWithPagination } from '@/components/DataTable/DataTable.types'
import { IAccountGroup } from '@/types/account-groups/account-group.interface'
import { IUser } from '@/types/account/user.interface'

interface Props {
  selected?: {
    type: 'all' | 'group' | 'account'
    value: string
  }
  setSelected: (selected?: {
    type: 'all' | 'group' | 'account'
    value: string
  }) => void
}

const NotificationsAutocomplete: React.FC<Props> = ({
  selected,
  setSelected
}) => {
  const [search, setSearch] = useState<string>('')

  const { data: groupsData, isLoading: isGroupsLoading } = useQuery({
    queryKey: ['account-groups', search],
    queryFn: async () => {
      const response = await api<IAccountGroup[]>('admin/account-groups', {
        method: 'GET',
        params: {
          search,
          isFullList: true
        }
      })
      if (response.data && response.data.length) {
        const result = response.data.map(group => ({
          label: (
            <p className='flex min-w-full flex-row justify-between text-sm'>
              <span>{group.name}</span>
              <span className='text-xs text-foreground/50'>
                {group._count.accounts} users
              </span>
            </p>
          ),
          value: group.id
        }))

        result.unshift({
          label: (
            <p className='flex min-w-full flex-row justify-between text-sm'>
              Send to All
            </p>
          ),
          value: 'all'
        })

        return result
      } else {
        return [{ label: 'Send to All', value: 'all' }]
      }
    }
  })

  const { data: usersData, isLoading: isUsersLoading } = useQuery({
    queryKey: ['users', search],
    queryFn: async () => {
      const response = await api<ResultWithPagination<IUser>>('admin/users', {
        method: 'POST',
        params: {
          search
        }
      })
      if (response.data.result && response.data.result.length) {
        const result = response.data.result.map(user => ({
          label: (
            <p className='flex min-w-full flex-row justify-between text-sm'>
              <span>@{user.username}</span>
              <span className='text-xs text-foreground/50'>{user.email}</span>
            </p>
          ),
          value: user.id
        }))

        return result
      }
    },
    enabled: search.length > 2
  })

  return (
    <Autocomplete
      search={search}
      setSearch={setSearch}
      value={selected?.value}
      setValue={value => {
        if (!value) {
          setSelected(undefined)
          return
        }
        if (value === 'all') {
          setSelected({ type: 'all', value: 'all' })
          return
        }
        if (search.length > 2) {
          setSelected({ type: 'account', value })
          return
        }
        setSelected({ type: 'group', value })
      }}
      data={search.length > 2 ? usersData || [] : groupsData || []}
      title={search.length > 2 ? 'Select Account' : 'Select Group'}
      className='sm:!min-w-[400px]'
      loading={isGroupsLoading || isUsersLoading}
    />
  )
}

export default NotificationsAutocomplete
