import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'

import { IBankFunding } from '@/types/bank-fundings/bank-fundings.interface'
import { BankAsset } from '@/types/enums'

import formatId from '@/utils/formatId'

import { formatNumber, msatsToSats } from '@/utils'

export const fundingsColumns: ColumnDef<IBankFunding>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'provider_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Provider ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.provider_id}`)
            toast.success('Provider ID Copied to clipboard')
          }}
        >
          {formatId(row.original.provider_id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'asset',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Asset' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.asset}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'side',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Side' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.side}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      return <p>{row.original.status}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'failure_code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Failure Code' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.failure_code || 'N/A'}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.asset === BankAsset.USD
            ? `$${formatNumber(String(row.original.amount))}`
            : `${msatsToSats(String(row.original.amount))} sats`}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'fee',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Fee' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          ${formatNumber(String(row.original.fee))}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'payment_rail',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Payment Rail' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.payment_rail || 'N/A'}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    },
    enableSorting: false
  }
]
