import { Switch } from '@nextui-org/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { Modal } from '@/components/Modal'

import { ICreateAccessCode } from '@/types/access-code/access-code.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

export const CreateAccessCode = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [type, setType] = useState<'individual' | 'group'>('individual')

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['create-access-code'],
    mutationFn: async (data: ICreateAccessCode) => {
      const resp = await api.post(
        `/admin/access-codes`,
        type === 'individual' ? { code: data.code } : { count: data.count }
      )

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success(
        type === 'individual' ? 'Access code created' : 'Access codes created'
      )
    },
    onError: error => {
      toast.error(errorCatch(error) || 'An error occurred')
    }
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateAccessCode>({
    defaultValues: {
      code: '',
      count: 1
    }
  })

  const onSubmit = (data: ICreateAccessCode) => {
    mutate(data)
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)} title='Create Code' />
      <Modal visible={isOpen} onClose={() => setIsOpen(false)} overflow={false}>
        <Loader loading={isPending} type='absolute' />
        <Modal.Header
          title={
            type === 'individual' ? 'Create Access Code' : 'Create Access Codes'
          }
          closable
          className='mb-[30px]'
        />
        <Modal.Content>
          <form className='flex w-full flex-col gap-5'>
            <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
              <p>{type === 'individual' ? 'Create one' : 'Create many'}</p>
              <Switch
                color='success'
                isSelected={type === 'group'}
                onValueChange={() =>
                  setType(type =>
                    type === 'individual' ? 'group' : 'individual'
                  )
                }
              />
            </label>

            {type === 'individual' ? (
              <Field
                placeholder='Access Code'
                type='text'
                {...register('code', {
                  required: 'This field is required',
                  minLength: {
                    value: 5,
                    message: 'Access code must be at least 5 characters'
                  },
                  maxLength: {
                    value: 5,
                    message: 'Access code must not exceed 5 characters'
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9]+$/,
                    message: 'Invalid access code'
                  }
                })}
                error={errors.code?.message}
              />
            ) : (
              <Field
                placeholder='Count'
                type='number'
                {...register('count', {
                  required: 'This field is required',
                  min: {
                    value: 1,
                    message: 'Count must be at least 1'
                  },
                  setValueAs: value => Number(value)
                })}
                error={errors.count?.message}
              />
            )}

            <FormButton
              type='submit'
              title={isPending ? 'Creating...' : 'Create'}
              disabled={isPending}
              stopPropagation
              preventDefault
              className='bg-orange550 text-white'
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}
