import { Switch } from '@nextui-org/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import api from '@/api'
import { errorCatch } from '@/api/error'
import AccountSelect from '@/components/AccountSelect'
import { AddButton } from '@/components/Buttons/AddButton'
import { Drawer } from '@/components/Drawer'
import { FormButton } from '@/components/FormButton'
import Icon from '@/components/Icon'
import { Loader } from '@/components/Loader'
import { ErrorMessage } from '@/components/Message/ErrorMessage'
import { ICreateEddRequest } from '@/types/bank-edd-request/bank-request.interface'

const CreateEDDRequest: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ICreateEddRequest>({
    mode: 'onChange',
    defaultValues: {
      account_id: undefined,
      first_name_visible: false,
      last_name_visible: false,
      address_one_visible: false,
      address_two_visible: false,
      city_visible: false,
      zip_visible: false,
      id_number_type_visible: false,
      phone_number_visible: false,
      employment_status_visible: false,
      industry_visible: false,
      source_of_funds_visible: false,
      signed_agreements_visible: false,
      onboarded_location_visible: false,
      jurisdiction_code_visible: false,
      citizenship_code_visible: false
    }
  })

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: ['create-edd-request'],
    mutationFn: (data: ICreateEddRequest) =>
      api('/admin/bank/edd', {
        method: 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(`EDD request created successfully!`)
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || `Failed to 'create' EDD request`)
    }
  })

  const onSubmit: SubmitHandler<ICreateEddRequest> = async data => mutate(data)

  return (
    <>
      <AddButton title='Create EDD Request' onClick={() => setIsOpen(true)} />
      <Drawer
        contentClassName='bg-gray800 border-solid border-gray500/50 w-full sm:w-[480px]'
        open={isOpen}
        position='right'
        onClose={() => setIsOpen(false)}
      >
        <Loader type='absolute' loading={isPending} />
        <div className='flex h-full flex-col gap-5 overflow-y-auto p-5 px-14 sm:py-10'>
          <div className='flex flex-row items-center justify-between'>
            <h2 className='text-2xl font-semibold'>Create EDD Request</h2>
            <Icon
              icon='Close'
              onClick={() => setIsOpen(false)}
              className='cursor-pointer fill-white opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
            />
          </div>
          <form className='flex flex-col gap-5'>
            <Controller
              name='account_id'
              control={control}
              rules={{ required: 'Account is required' }}
              render={({ field }) => (
                <label className='flex flex-col gap-2'>
                  <p className='font-semibold'>Account</p>
                  <AccountSelect
                    accountId={field.value}
                    setAccountId={field.onChange}
                  />
                  {errors.account_id?.message && (
                    <ErrorMessage message={errors.account_id.message} />
                  )}
                </label>
              )}
            />
            <p className='font-semibold'>Details</p>
            <Controller
              name='first_name_visible'
              control={control}
              render={({ field }) => (
                <label className='flex cursor-pointer items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>First Name</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='last_name_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Last Name</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='address_one_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Address One</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='address_two_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Address Two</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='city_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>City</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='zip_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Zip</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='id_number_type_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>ID Number Type</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='phone_number_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Phone Number</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='employment_status_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Employment Status</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='industry_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Industry</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='source_of_funds_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Source Of Funds</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='onboarded_location_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Onboarded Location</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='jurisdiction_code_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Jurisdiction Code</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <Controller
              name='citizenship_code_visible'
              control={control}
              render={({ field }) => (
                <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Citizenship Code</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />

            <FormButton
              onClick={handleSubmit(onSubmit)}
              preventDefault
              stopPropagation
              title='Confirm'
              className='mt-2 bg-orange550 text-white'
            />
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default CreateEDDRequest
