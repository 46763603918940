import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import api from '@/api'
import { AddButton } from '@/components/Buttons/AddButton'
import { FormButton } from '@/components/FormButton'
import { Input } from '@/components/Input'
import { Loader } from '@/components/Loader'
import { ErrorMessage } from '@/components/Message/ErrorMessage'
import { Modal } from '@/components/Modal'
import { ICreateAccountGroup } from '@/types/account-groups/account-group.interface'

export const CreateGroup = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['createAccountGroup'],
    mutationFn: async (data: ICreateAccountGroup) => {
      const resp = await api.post(`/admin/account-groups`, {
        ...data
      })

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success('Group successfully created!', {
        id: 'account-groups'
      })
    },
    onError: error => {
      toast.error(error.message, {
        id: 'account-groups'
      })
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateAccountGroup>({
    defaultValues: {
      name: ''
    }
  })

  const onSubmit = (data: ICreateAccountGroup) => {
    mutate(data)
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)} title='Create Group' />
      <Modal visible={isOpen} onClose={() => setIsOpen(false)} overflow={false}>
        <Loader loading={isPending} type='absolute' />
        <Modal.Header title='Create group' closable className='mb-[30px]' />
        <Modal.Content>
          <form className='flex w-full flex-col gap-5'>
            <div>
              <Controller
                name='name'
                control={control}
                rules={{
                  required: 'Group name is required'
                }}
                render={({ field }) => {
                  return (
                    <Input
                      className='bg-gray900 text-[16px] font-bold'
                      type='text'
                      name='notification'
                      placeholder='Group name'
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )
                }}
              />
              {errors.name?.message && (
                <ErrorMessage message={errors.name.message} />
              )}
            </div>
            <FormButton
              type='submit'
              title={isPending ? 'Creating...' : 'Create'}
              disabled={isPending}
              stopPropagation
              preventDefault
              className='bg-orange550 text-white'
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}
