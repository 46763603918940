import { useState } from 'react'

import getFailureCodeDescription from '@/constants/failure-codes-descriptions'

import { Button } from '@/components/DataTable/Button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/PopoverNew'

interface Props {
  failureCode: string
}

const FailureCodeComponent: React.FC<Props> = ({ failureCode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={'link'}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className='!m-0 !p-0'
        >
          {failureCode}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <h5 className='font-bold'>Description</h5>
        <p>{getFailureCodeDescription(failureCode)}</p>
      </PopoverContent>
    </Popover>
  )
}

export default FailureCodeComponent
