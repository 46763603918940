const failureCodeDescriptions: {
  [key: string]: string
} = {
  'kyc.age.under_18': 'You must be at least 18 years old to use our services.',
  'kyc.device.malware_detected':
    'Malware was detected on your device. Ensure your device is secure and try again.',
  'kyc.device.tor_network_or_aggregator_detected':
    'Your device is using a Tor network or aggregator. Please disable and retry.',
  'kyc.device.blocklisted':
    'Your device is blacklisted due to suspicious activity.',
  'kyc.device.jailbroken':
    'Your device is jailbroken. Please use a standard device for verification.',
  'kyc.device.vpn_detected':
    'Your are using a VPN, which hides your location. Please turn it off and try again.',
  'kyc.device.open_anonymous_or_hidden_proxy':
    'Your device is using an open, anonymous, or hidden proxy. Please disable it and retry.',
  'kyc.device.bot_or_suspicious_activity':
    'Unusual or bot-like activity was detected from your device.',
  'kyc.email.email_high_risk':
    'Your email has been flagged as high risk. Please try to create an account with a different email.',
  'kyc.idv.submitted_name_does_not_match_id':
    "The name you provided doesn't match the name on your ID. Double-check and try again.",
  'kyc.idv.submitted_date_of_birth_does_not_match_id':
    'The date of birth you provided does not match the one on your ID. Verify your information.',
  'kyc.idv.name_not_extracted':
    'We could not extract your name from your ID. Make sure it is clear and try again.',
  'kyc.idv.could_not_be_processed':
    'There was an issue processing your ID. This might be due to the ID quality or format.',
  'kyc.idv.barcode_not_scannable':
    'The barcode on your ID could not be scanned. Ensure it is clear and undamaged.',
  'kyc.idv.barcode_does_not_match_id':
    'The information on your ID does not match the barcode. Check for errors or use a different ID.',
  'kyc.idv.image_suspicious':
    'The image on your ID is unclear or invalid. Submit a clearer image or a different ID.',
  'kyc.idv.suspicious_id':
    'Your ID may be tampered with or inauthentic. Ensure it is genuine and try again.',
  'kyc.idv.expired':
    'The ID you provided has expired. Please use a valid, unexpired ID.',
  'kyc.idv.missing_expected_portraits':
    'Your ID should include a photo. Ensure all required details are present.',
  'kyc.idv.document_type_and_country_mismatch':
    'The document type and country you selected do not match the scanned ID. Check your selections.',
  'kyc.jurisdiction.region_blocklisted':
    'We cannot verify accounts from your region due to restrictions.',
  'kyc.liveness.id_does_not_match_selfie':
    'The selfie you took does not match the photo on your ID. Take a clear selfie.',
  'kyc.liveness.failed':
    'We could not confirm your selfie video shows a live person. Ensure you are visible and try again.',
  'kyc.pii.national_id_not_present':
    'You need to provide a national ID (e.g., SSN) for verification.',
  'kyc.pii.address_not_associated_with_phone_number':
    'Your address is not associated with your phone number in our records.',
  'kyc.pii.address_not_associated_with_name':
    'Your address is not associated with your name in our records.',
  'kyc.pii.address_not_associated_with_national_id':
    'Your address is not associated with your national ID (e.g., SSN) in our records.',
  'kyc.pii.date_of_birth_not_associated_with_name':
    'Your date of birth is not associated with your name in our records.',
  'kyc.pii.date_of_birth_not_associated_with_national_id':
    'Your date of birth is not associated with your national ID (e.g., SSN) in our records.',
  'kyc.pii.name_not_associated_with_national_id':
    'Your name is not associated with your national ID (e.g., SSN) in our records.',
  'kyc.pii.name_not_associated_with_phone_number':
    'Your name is not associated with your phone number in our records.',
  'kyc.pii.name_not_found_in_data':
    'We could not find your name in our records. Please verify your information.',
  'kyc.pii.national_id_invalid_format':
    'The national ID number you provided is incorrectly formatted. Please use the correct format.',
  'kyc.pii.national_id_mismatch':
    'The national ID number you provided does not match our records. Verify your details.',
  'kyc.pii.national_id_deceased':
    'The national ID number you provided belongs to a deceased person. Verify and correct the information.',
  'kyc.pii.national_id_synthetic_or_stolen':
    'The national ID number you provided appears to be stolen or synthetic. Use a genuine ID.',
  'kyc.pii.suspicious_phone_number':
    'The phone number you provided is flagged as suspicious. Please use a different number.',
  'kyc.sanctions.name_on_sanctions_list':
    'Your name matches a name on a sanctions list. Account creation may be restricted.',
  'kyc.watchlists.name_on_watchlists':
    'Your name matches a name on a watchlist. Account creation may be restricted.',
  'kyc.other.checks_failed':
    'There was a general error. Please contact Customer Support for assistance.',
  'kyc.other.manual_review_result':
    'There was an issue with the review process. Please contact Customer Support for assistance.'
}

const getFailureCodeDescription = (failureCode: string) => {
  return (
    failureCodeDescriptions[failureCode] ||
    'No description available for this failure code'
  )
}

export default getFailureCodeDescription
