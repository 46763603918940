import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import api from '@/api'
import { tokens } from '@/constants/cookies'
import { useActions } from '@/hooks/use-actions'
import { useAuth } from '@/hooks/use-auth'
import { IAdmin } from '@/types/account/admin.interface'

import { Loader } from '@/components/Loader'

const getCachedProfile = () => {
  const cachedData = localStorage.getItem('cachedProfile')
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData)
    const now = Date.now()

    if (now - timestamp < 5 * 60 * 1000) {
      return data
    } else {
      localStorage.removeItem('cachedProfile')
    }
  }
  return null
}

const cacheProfile = (data: IAdmin) => {
  localStorage.setItem(
    'cachedProfile',
    JSON.stringify({ data, timestamp: Date.now() })
  )
}

export function AuthProvider() {
  const [authorized, setAuthorized] = useState<boolean | undefined>(undefined)
  const { admin, isAuthenticated } = useAuth()
  const { logout, updateAdmin } = useActions()
  const navigate = useNavigate()
  const location = useLocation()

  const { mutate } = useMutation({
    mutationKey: ['profile'],
    mutationFn: async () => {
      const accessToken = localStorage.getItem(tokens.accessToken)

      if (!accessToken) {
        logout()
        return null
      }

      const { data } = await api.get<IAdmin>('/admin/profile')
      return data
    },
    retry: 1,
    onError: () => {
      toast.error('Failed to authenticate')
      setAuthorized(false)
    },
    onSuccess: resp => {
      if (resp) {
        cacheProfile(resp)
        updateAdmin(resp)
        setAuthorized(true)
      } else {
        setAuthorized(false)
      }
    }
  })

  useEffect(() => {
    if (!admin || !isAuthenticated) {
      if (authorized !== false) setAuthorized(false)
      if (location.pathname !== '/login') {
        navigate('/login', { replace: true })
      }
    } else {
      const cachedProfile = getCachedProfile()

      if (cachedProfile) {
        if (authorized !== true) setAuthorized(true)
      } else {
        mutate()
      }
    }
  }, [location.pathname, isAuthenticated])

  useEffect(() => {
    if (authorized && location.pathname === '/login') {
      navigate('/users', { replace: true })
    }
  }, [authorized, location.pathname, navigate])

  return (
    <>
      {authorized !== undefined ? (
        authorized || location.pathname === '/login' ? (
          <Outlet />
        ) : (
          <Navigate to='/login' />
        )
      ) : (
        <Loader loading={true} className='z-20' type='fixed' />
      )}
    </>
  )
}
