'use client'

import { ArrowUpDown, Check } from 'lucide-react'
import * as React from 'react'

import { Button } from '@/components/DataTable/Button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/PopoverNew'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/Сommand'
import { cn } from '@/utils'

interface Props {
  search: string
  setSearch: (value: string) => void
  value: string | undefined
  setValue: (value: string | undefined) => void
  data: { label: string | React.ReactNode; value: string }[]
  title: string
  loading?: boolean
  disabled?: boolean
  className?: string
}

const Autocomplete: React.FC<Props> = ({
  value,
  setValue,
  data,
  title,
  search,
  setSearch,
  loading,
  disabled,
  className
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant='secondary'
          role='combobox'
          aria-expanded={open}
          className='w-full justify-between px-4 py-8'
        >
          <p className='w-full max-w-full truncate text-start'>
            {value
              ? data.find(framework => framework.value === value)?.label
              : title}
          </p>
          <ArrowUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn('min-w-full p-0 sm:w-[350px]', className)}>
        <Command shouldFilter={false} loop className='w-full' value={value}>
          <CommandInput
            placeholder='Search...'
            className='h-9'
            value={search}
            onValueChange={value => setSearch(value)}
          />
          <CommandList>
            {loading && <CommandEmpty>Loading...</CommandEmpty>}
            {!loading && (
              <CommandEmpty>
                {data.length === 0 ? 'No results' : 'No results found'}
              </CommandEmpty>
            )}
            <CommandGroup>
              {data.map(item => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={currentValue => {
                    setValue(currentValue === value ? undefined : currentValue)
                    setOpen(false)
                  }}
                  className={cn(
                    'flex items-center justify-between p-2',
                    value === item.value && 'bg-primary text-white'
                  )}
                >
                  {item.label}
                  {value === item.value && <Check />}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default Autocomplete
