export default function Blocked({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='24'
      viewBox='0 0 32 24'
      className={className}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect
            id='Rectangle_5595'
            data-name='Rectangle 5595'
            width='32'
            height='24'
            fill='currentColor'
          />
        </clipPath>
      </defs>
      <g
        id='Satoshi_Logomark'
        data-name='Satoshi Logomark'
        clipPath='url(#clip-path)'
      >
        <path
          id='Path_985'
          data-name='Path 985'
          d='M14.667,9.053a5.053,5.053,0,1,1,5.053,5.053A5.053,5.053,0,0,1,14.667,9.053Z'
          transform='translate(-3.667 -1)'
          fill='#f7931a'
          fillRule='evenodd'
        />
        <path
          id='Path_986'
          data-name='Path 986'
          d='M9.007,5.2a8.053,8.053,0,1,0,14.081-.022l.832-.194C28.746,3.858,32,3.1,32,9.051a16,16,0,1,1-32,0C0,3.1,3.253,3.858,8.08,4.988Z'
          transform='translate(0 -1.052)'
          fill='currentColor'
          fillRule='evenodd'
        />
      </g>
    </svg>
  )
}
