import { IExchangeLimits } from '@/types/account/limits.interface'

interface Props {
  limits: IExchangeLimits
}

const ExchangeLimits: React.FC<Props> = ({ limits }) => {
  return (
    <section className='flex flex-col gap-2 xl:col-span-2'>
      <h2 className='text-xl font-bold'>Exchange Wallet Limits</h2>

      <section className='flex flex-col gap-2 xl:grid xl:grid-cols-2 xl:gap-4'>
        {/* ACH Deposits */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>ACH Deposits</h3>
          <p className='text-foreground/50'>
            Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.ach_deposit_transaction_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Available Transaction USD:{' '}
            <b className='text-foreground'>
              ${limits.ach_deposit_transaction_available_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Monthly Limit Remaining USD:{' '}
            <b className='text-foreground'>
              ${limits.ach_deposit_monthly_limit_remaining_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Monthly Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.ach_deposit_monthly_limit_usd.toLocaleString()}
            </b>
          </p>
        </div>

        {/* ACH Withdrawals */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>ACH Withdrawals</h3>
          <p className='text-foreground/50'>
            Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.ach_withdrawal_transaction_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Available Transaction USD:{' '}
            <b className='text-foreground'>
              $
              {limits.ach_withdrawal_transaction_available_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Monthly Limit Remaining USD:{' '}
            <b className='text-foreground'>
              $
              {limits.ach_withdrawal_monthly_limit_remaining_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Monthly Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.ach_withdrawal_monthly_limit_usd.toLocaleString()}
            </b>
          </p>
        </div>

        {/* Trades */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>Trades</h3>
          <p className='text-foreground/50'>
            Trade Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.trade_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Trade Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.trade_daily_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Trade Available USD:{' '}
            <b className='text-foreground'>
              ${limits.trade_daily_available_usd.toLocaleString()}
            </b>
          </p>
        </div>

        {/* Lightning Deposits */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>Lightning Deposits</h3>
          <p className='text-foreground/50'>
            Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.lightning_deposit_transaction_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.lightning_deposit_transaction_daily_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Available USD:{' '}
            <b className='text-foreground'>
              $
              {limits.lightning_deposit_transaction_daily_available_usd.toLocaleString()}
            </b>
          </p>
        </div>

        {/* Lightning Withdrawals */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>Lightning Withdrawals</h3>
          <p className='text-foreground/50'>
            Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.lightning_withdrawal_transaction_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Transaction Limit USD:{' '}
            <b className='text-foreground'>
              $
              {limits.lightning_withdrawal_transaction_daily_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Available USD:{' '}
            <b className='text-foreground'>
              $
              {limits.lightning_withdrawal_transaction_daily_available_usd.toLocaleString()}
            </b>
          </p>
        </div>

        {/* Onchain Sends */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>Onchain Sends</h3>
          <p className='text-foreground/50'>
            Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.onchain_send_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.onchain_send_daily_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Available USD:{' '}
            <b className='text-foreground'>
              ${limits.onchain_send_daily_available_usd.toLocaleString()}
            </b>
          </p>
        </div>

        {/* Book Transfers */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>Book Transfers</h3>
          <p className='text-foreground/50'>
            Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.book_transfer_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Transaction Limit USD:{' '}
            <b className='text-foreground'>
              ${limits.book_transfer_daily_usd.toLocaleString()}
            </b>
          </p>
          <p className='text-foreground/50'>
            Daily Available USD:{' '}
            <b className='text-foreground'>
              ${limits.book_transfer_daily_available_usd.toLocaleString()}
            </b>
          </p>
        </div>

        {/* Bank Accounts */}
        <div className='flex break-inside-avoid flex-col gap-1'>
          <h3 className='text-lg font-semibold'>Bank Accounts</h3>
          <p className='text-foreground/50'>
            Linkable Bank Accounts Available:{' '}
            <b className='text-foreground'>
              {limits.linkable_bank_accounts_available}
            </b>
          </p>
          <p className='text-foreground/50'>
            Total Linkable Bank Accounts:{' '}
            <b className='text-foreground'>{limits.linkable_bank_accounts}</b>
          </p>
          <p className='text-foreground/50'>
            Deposits Weekly:{' '}
            <b className='text-foreground'>{limits.deposits_weekly}</b>
          </p>
          <p className='text-foreground/50'>
            Concurrent Transactions:{' '}
            <b className='text-foreground'>{limits.concurrent_transactions}</b>
          </p>
        </div>
      </section>
    </section>
  )
}

export default ExchangeLimits
