'use client'

import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { DataTable } from '@/components/DataTable'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/DataTable/Select'
import Icon from '@/components/Icon'

import { KycStatusEnum } from '@/types/account/user.interface'

import api from '@/api'
import UserMetricItem from '@/routes/Users/MetricItem'
import { columns } from '@/routes/Users/columns'

export function Users() {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['users-metrics'],
    queryFn: async () => {
      const resp = await api.get<{
        totalUsers: number
        totalUsersWithAvatar: {
          count: number
          percentage: number
        }
        totalUsersWithKyc: {
          count: number
          percentage: number
        }
        totalUsersWithSatoshiPlus: {
          count: number
          percentage: number
        }
      }>('/admin/users/metrics')

      return resp.data
    }
  })

  const [kycStatus, setKycStatus] = useState<KycStatusEnum | 'all' | undefined>(
    undefined
  )

  return (
    <>
      <Helmet>
        <title>STBA Admin - Users</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white/15 pb-7 text-2xl font-bold text-white md:text-3xl'>
          Users
        </h1>
        <div className='mt-2 grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 xl:grid-cols-4 xl:gap-6'>
          <UserMetricItem
            label='Has avatar'
            count={data?.totalUsersWithAvatar.count || 0}
            percentage={data?.totalUsersWithAvatar.percentage || 0}
            isLoading={isLoading}
            error={isError ? 'Failed to fetch users metrics' : undefined}
          />
          <UserMetricItem
            label="Is KYC'd"
            count={data?.totalUsersWithKyc.count || 0}
            percentage={data?.totalUsersWithKyc.percentage || 0}
            isLoading={isLoading}
            error={isError ? 'Failed to fetch users metrics' : undefined}
          />
          <UserMetricItem
            label='Has Satoshi+'
            count={data?.totalUsersWithSatoshiPlus.count || 0}
            percentage={data?.totalUsersWithSatoshiPlus.percentage || 0}
            isLoading={isLoading}
            error={isError ? 'Failed to fetch users metrics' : undefined}
          />
        </div>
        <DataTable
          columns={columns}
          url='/admin/users'
          totalText='users'
          className='mt-2'
          tableClassName='md:!max-h-[calc(100vh-550px)]'
          body={{ status: kycStatus }}
          method='POST'
        >
          <Select
            value={kycStatus}
            onValueChange={val => {
              if (val === undefined) {
                setKycStatus(undefined)
                return
              }
              setKycStatus(val as KycStatusEnum | undefined)
            }}
          >
            <SelectTrigger className='mt-0 w-[180px] rounded-3xl'>
              <SelectValue placeholder='Select KYC Status' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={'all'}>All</SelectItem>
              <SelectItem value={KycStatusEnum.FAILED}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='Rejected' className='size-4' />
                  Failed
                </div>
              </SelectItem>
              <SelectItem value={KycStatusEnum.STARTED}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='InProgress' className='size-4' />
                  Started
                </div>
              </SelectItem>
              <SelectItem value={KycStatusEnum.VERIFIED}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='Approved' className='size-4' />
                  Verified
                </div>
              </SelectItem>
              <SelectItem value={KycStatusEnum.VERIFIED_NO_SSN}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='Approved' className='size-4' />
                  Verified No SSN
                </div>
              </SelectItem>
              <SelectItem value={KycStatusEnum.UNVERIFIED}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='Unverified' className='size-4' />
                  Unverified
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        </DataTable>
      </section>
    </>
  )
}
