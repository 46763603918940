import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { DataTable } from '@/components/DataTable'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/DataTable/Select'
import Icon from '@/components/Icon'
import ProtectedComponent from '@/components/ProtectedComponent'

import { CreateAccessCode } from './Create'
import { columns } from './columns'

export function AccessCodes() {
  const [isClaimed, setIsClaimed] = useState<'all' | 'claimed' | 'unclaimed'>(
    'all'
  )

  return (
    <ProtectedComponent permissionSlug='access_codes_view' isPage>
      <Helmet>
        <title>STBA Admin - Access Codes</title>
      </Helmet>
      <section className='gap- flex w-full flex-col p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <section className='flex w-full flex-wrap justify-between gap-2 border-b border-white/15 pb-7'>
          <h1 className='text-2xl font-bold text-white md:text-3xl'>
            Access Codes
          </h1>
          <ProtectedComponent permissionSlug='access_codes_create'>
            <CreateAccessCode />
          </ProtectedComponent>
        </section>
        <DataTable
          url='admin/access-codes/query'
          columns={columns}
          method='POST'
          body={{
            isClaimed:
              isClaimed === 'all'
                ? undefined
                : isClaimed === 'claimed'
                  ? true
                  : false
          }}
        >
          <Select
            value={isClaimed}
            onValueChange={(val: 'all' | 'claimed' | 'unclaimed') =>
              setIsClaimed(val)
            }
          >
            <SelectTrigger className='mt-0 w-[180px] rounded-3xl'>
              <SelectValue placeholder='Select KYC Status' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={'all'}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='Unverified' className='size-4' />
                  All
                </div>
              </SelectItem>
              <SelectItem value={'claimed'}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='Rejected' className='size-4' />
                  Claimed
                </div>
              </SelectItem>
              <SelectItem value={'unclaimed'}>
                <div className='flex flex-row items-center gap-2'>
                  <Icon icon='Approved' className='size-4' />
                  Unclaimed
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        </DataTable>
      </section>
    </ProtectedComponent>
  )
}
