import { Outlet } from 'react-router-dom'

import { Header } from '@/components/Layout/Header'
import { Sidebar } from '@/components/Layout/Sidebar'

export const Layout = () => {
  return (
    <>
      <div className='fixed top-0 z-10 h-12 w-full bg-gray900'>
        <Header />
      </div>
      <div className='relative mt-12 flex min-h-[calc(100vh-48px)] w-full'>
        <Sidebar />
        <div className='relative top-0 h-full w-full min-w-0'>
          <Outlet />
        </div>
      </div>
    </>
  )
}
