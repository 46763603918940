interface Props {
  avatar: string | null
  name: string
  nameClassName?: string
  onPress?: () => void
}
const UserCard: React.FC<Props> = ({
  avatar,
  name,
  nameClassName,
  onPress
}) => {
  return (
    <button
      className='flex items-center gap-2 overflow-hidden whitespace-nowrap'
      onClick={onPress}
    >
      {avatar ? (
        <div
          className='size-8 flex-none rounded-full bg-cover bg-center'
          style={{
            backgroundImage: `url(${avatar})`
          }}
        />
      ) : (
        <div className='size-8 flex-none rounded-full bg-foreground/20' />
      )}
      <p className={nameClassName} title={name}>
        {name}
      </p>
    </button>
  )
}

export default UserCard
