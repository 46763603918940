import { DataTable } from '@/components/DataTable'

import { columns } from './columns'

export const ReferTransactions = () => {
  return (
    <DataTable
      url='admin/refer/transactions'
      totalText='transactions'
      columns={columns}
      tableClassName='md:!max-h-[calc(100vh-550px)]'
    />
  )
}
