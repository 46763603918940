import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'
import UserDetailsModal from '@/components/UserDetails/Modal'

import { IBankConnection } from '@/types/bank-connection/bank-connection.interface'

import formatId from '@/utils/formatId'

export const columns: ColumnDef<IBankConnection>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'provider_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Provider ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.provider_id}`)
            toast.success('Provider ID Copied to clipboard')
          }}
        >
          {formatId(row.original.provider_id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'bank_customer.account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      return (
        <UserDetailsModal
          fromOtherPage
          user={row.original.bank_customer.account}
        />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => {
      return <p>{row.original.name}</p>
    }
  },
  {
    accessorKey: 'asset',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Asset' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.asset}</p>
    }
  },
  {
    accessorKey: 'provider',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Provider' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.provider}</p>
    }
  },
  {
    accessorKey: 'institution_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Institution ID' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.institution_id}</p>
    }
  },
  {
    accessorKey: 'institution_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Institution Name' />
    },
    cell: ({ row }) => {
      return <p>{row.original.institution_name}</p>
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      return <p>{row.original.status}</p>
    }
  },
  {
    accessorKey: 'account_type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account Type' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.account_type}</p>
    }
  },
  {
    accessorKey: 'account_subtype',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account Subtype' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.account_subtype}</p>
      )
    }
  },
  {
    accessorKey: 'account_number',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account Number' />
    },
    cell: ({ row }) => {
      if (row.original.account_number) {
        return (
          <button
            onClick={() => {
              navigator.clipboard.writeText(`${row.original.account_number}`)
              toast.success('Number Copied to clipboard')
            }}
          >
            {formatId(row.original.account_number)}
          </button>
        )
      }
      return <p className='text-foreground/50'>N/A</p>
    }
  },
  {
    accessorKey: 'account_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account Name' />
    },
    cell: ({ row }) => {
      return <p>{row.original.account_name}</p>
    }
  },
  {
    accessorKey: 'failure_code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Failure Code' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.failure_code ? row.original.failure_code : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'agreed_to_ach_terms_at',
    header: ({ column }) => {
      return (
        <DataTableColumnHeader column={column} name='Agreed to ACH Terms At' />
      )
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.agreed_to_ach_terms_at
            ? new Date(row.original.agreed_to_ach_terms_at).toLocaleDateString(
                'en-US',
                {
                  hour: '2-digit',
                  minute: '2-digit',
                  day: 'numeric',
                  month: 'short'
                }
              )
            : 'N/A'}
        </p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    }
  }
]
