import { IUser } from './user.interface'

export interface IUserUpdate {
  id: string
  first_name?: string
  last_name?: string
  username?: string
  display_name?: string
  email: string
  // type: AccountType
  // membership_type: MembershipType
  accrued_balance_msats?: number
  daily_earn_rate_msats?: number
  streak_count?: number
  earnings_msats: number
  earned_unclaimed_msats: number
  referral_code?: string
  refer_earnings_msats?: string
  refer_claimable_msats?: string
}

export const mapIUserToIUserUpdate = (user: IUser): IUserUpdate => ({
  id: user.id,
  first_name: user.first_name,
  last_name: user.last_name,
  username: user.username,
  display_name: user.display_name,
  email: user.email,
  accrued_balance_msats: Number(user.accrued_balance_msats),
  daily_earn_rate_msats: Number(user.daily_earn_rate_msats),
  streak_count: user.streak_count,
  earnings_msats: Number(user.earnings_msats),
  earned_unclaimed_msats: Number(user.earned_unclaimed_msats),
  referral_code: user.referral_code,
  refer_earnings_msats: user.refer_earnings_msats,
  refer_claimable_msats: user.refer_claimable_msats
})
