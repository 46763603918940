import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import { cn } from '@/utils'

export type DrawerPosition = 'left' | 'right' | 'top' | 'bottom'

interface DrawerWrapperProps {
  children?: ReactNode | ReactNode[]
  position: DrawerPosition
  open: boolean
  onClose: () => void
  contentClassName?: string
  wrapperClassName?: string
}

const TRANSITION_DURATION = 300
export const DrawerWrapper = ({
  children,
  position,
  open,
  onClose,
  contentClassName: propsContentClassName,
  wrapperClassName
}: DrawerWrapperProps) => {
  const [zIndex, setZIndex] = useState<number>(open ? 1 : -1)
  const [currentOpen, setCurrentOpen] = useState<boolean>(open)

  useEffect(() => {
    const newZIndex = open ? 1 : -1
    setTimeout(
      () => {
        setZIndex(newZIndex)
      },
      newZIndex === -1 ? TRANSITION_DURATION : 0
    )
  }, [open])

  useEffect(() => {
    setCurrentOpen(open)
  }, [open])

  const contentClassName = useMemo(() => {
    const styles = {
      left: 'left-0 top-0 h-full',
      right: 'right-0 top-0 h-full',
      top: 'top-0 left-0 w-full',
      bottom: 'bottom-0 left-0 w-full'
    }

    return styles[position]
  }, [position])

  const contentOpenClassName = useMemo(() => {
    const styles = {
      left: currentOpen ? 'translate-x-0' : 'translate-x-[-100%]',
      right: currentOpen ? 'translate-x-0' : 'translate-x-[100%]',
      top: currentOpen ? 'translate-y-0' : 'translate-y-[-100%]',
      bottom: currentOpen ? 'translate-y-0' : 'translate-y-[100%]'
    }

    return styles[position]
  }, [currentOpen, position])

  const maskOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setCurrentOpen(false)

    setTimeout(() => {
      setZIndex(-1)
      onClose()
    }, TRANSITION_DURATION)
  }

  return (
    <div
      onClick={maskOnClick}
      className={cn(
        'fixed left-0 top-0 z-10 h-screen w-screen bg-black/60 transition-all duration-300',
        wrapperClassName,
        currentOpen
          ? 'opacity-1'
          : `${zIndex === -1 ? 'z-none' : 'z-1'} opacity-0`
      )}
    >
      <div
        onClick={e => {
          e.stopPropagation()
        }}
        className={cn(
          'absolute transition-all duration-300',
          contentClassName,
          contentOpenClassName,
          propsContentClassName
        )}
      >
        {children}
      </div>
    </div>
  )
}
