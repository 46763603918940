import { ILimits } from '@/types/account/limits.interface'

import ExchangeLimits from './Exchange'
import StarterLimits from './Starter'

interface LimitsCardsProps {
  hasExchange: boolean
  limits: ILimits
}

const UserLimits: React.FC<LimitsCardsProps> = ({ limits, hasExchange }) => {
  return (
    <>
      {hasExchange && <ExchangeLimits limits={limits.exchange} />}
      <StarterLimits limits={limits.starter} />
    </>
  )
}

export default UserLimits
