import { DataTable } from '@/components/DataTable'

import { columns } from './columns'

export const Configurations = () => {
  return (
    <DataTable
      url='admin/configuration'
      totalText='configurations'
      columns={columns}
      tableClassName='md:!max-h-[calc(100vh-550px)]'
    />
  )
}
