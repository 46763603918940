import { YoutubeVideoDataInterface } from '@/types/learn/youtube.interface'

export const getVideoId = (videoUrl: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_href, searchParams] = videoUrl.split('?')
  const queryParams = new URLSearchParams(searchParams)

  return queryParams.get('v')
}

export const getVideoDataByUrl = async (videoUrl: string) => {
  const videoId = getVideoId(videoUrl)

  const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics`

  const response = await fetch(apiUrl)
  return (await response.json()) as YoutubeVideoDataInterface
}

export const isValidYouTubeUrl = (url: string) => {
  const pattern =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

  return pattern.test(url)
}
