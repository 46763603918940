'use client'

import { ArrowUpDown, Check } from 'lucide-react'
import * as React from 'react'

import { Button } from '@/components/DataTable/Button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/PopoverNew'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/Сommand'

interface Props {
  search: string
  setSearch: (value: string) => void
  values: string[]
  setValues: (value: string[]) => void
  data: { label: string; value: string }[]
  title: string
  loading?: boolean
  disabled?: boolean
}

const MultipleAutocomplete: React.FC<Props> = ({
  values,
  setValues,
  data,
  title,
  search,
  setSearch,
  loading,
  disabled
}) => {
  const [open, setOpen] = React.useState(false)

  const toggleValue = (value: string) => {
    if (values.includes(value)) {
      setValues(values.filter(v => v !== value))
    } else {
      setValues([...values, value])
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant={'secondary'}
          role='combobox'
          aria-expanded={open}
          className='w-full justify-between border-2 !py-8 !font-bold dark:border-gray500/20 dark:bg-gray800'
        >
          <p
            className='w-full max-w-full truncate text-start'
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 1
            }}
          >
            {values.length > 0
              ? data
                  .filter(item => values.includes(item.value))
                  .map(item => item.label)
                  .join(', ')
              : title}
          </p>
          <ArrowUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='min-w-full p-0 sm:w-[350px]'>
        <Command shouldFilter={false} loop className='w-full'>
          <CommandInput
            placeholder='Search...'
            className='h-9'
            value={search}
            onValueChange={value => setSearch(value)}
          />
          <CommandList>
            {loading && <CommandEmpty>Loading...</CommandEmpty>}
            {!loading && (
              <CommandEmpty>
                {data.length === 0 ? 'No results' : 'No results found'}
              </CommandEmpty>
            )}
            <CommandGroup>
              {data.map(item => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    toggleValue(item.value)
                  }}
                  className='flex items-center justify-between p-2'
                >
                  {item.label}
                  {values.includes(item.value) && <Check />}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default MultipleAutocomplete
