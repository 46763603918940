import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import { EditButton } from '@/components/Buttons/EditButton'

interface AvatarUploadProps {
  image: string
  onImage: (file: File) => void
}

export const ImageUpload = ({ image, onImage }: AvatarUploadProps) => {
  const [currentImage, setCurrentImage] = useState<string | undefined>(image)

  useEffect(() => {
    setCurrentImage(image)
  }, [image])

  const onSelectImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = false
    input.accept = 'image/png, image/jpeg, image/jpg, image/webp'

    const MAX_FILE_SIZE = 2 * 1024 * 1024 // 2MB

    input.onchange = () => {
      const file = input.files?.[0]
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          toast.error('File size is too large. Max file size is 2MB.')
          input.remove()
          return
        }

        const reader = new FileReader()
        reader.addEventListener('load', event => {
          const data = event?.target?.result
          if (data) {
            setCurrentImage(data as string)
            onImage(file)
          }
        })
        reader.readAsDataURL(file)
      }

      input.remove()
    }

    input.click()
  }

  return (
    <div className='flex flex-1 flex-col items-center justify-between gap-[10px] rounded-[10px] border-2 border-gray500/20 bg-gray800 p-[20px]'>
      <span className='text-[12px] font-bold leading-[15px] text-white'>
        Image
      </span>

      {currentImage ? (
        <div
          className='h-[102px] w-[56px] overflow-hidden rounded-[10px] bg-cover bg-center'
          style={{
            backgroundImage: `url(${currentImage})`
          }}
        />
      ) : (
        <div className='h-[102px] w-[56px] overflow-hidden rounded-[10px] bg-white/20' />
      )}

      {!currentImage ? (
        <AddButton title='Add' onClick={onSelectImage} />
      ) : (
        <EditButton title='Edit' onClick={onSelectImage} />
      )}
    </div>
  )
}
