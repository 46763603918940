import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'

import { IUser } from '@/types/account/user.interface'

import formatId from '@/utils/formatId'

import { msatsToSats } from '@/utils'

export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'display_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Display Name' />
    },
    cell: ({ row }) => {
      if (row.original.display_name) {
        return <p>{row.original.display_name}</p>
      } else {
        return <p className='opacity-50'>-</p>
      }
    }
  },
  {
    accessorKey: 'legal_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Legal Name' />
    },
    cell: ({ row }) => {
      if (row.original.first_name && row.original.last_name) {
        return (
          <p>
            {row.original.first_name} {row.original.last_name}
          </p>
        )
      } else {
        return <p className='min-w-28 opacity-50'>Not Verified</p>
      }
    },
    enableSorting: false
  },
  {
    accessorKey: 'username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='opacity-50'
        >
          @{row.original.username}
        </button>
      )
    }
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='opacity-50'
        >
          {row.original.email}
        </button>
      )
    }
  },
  {
    accessorKey: 'refer_claimable_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Claimable' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-orange550'>
          {msatsToSats(row.original.refer_claimable_msats).toString()} sats
        </p>
      )
    }
  },
  {
    accessorKey: 'referrals',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Referred' />
    },
    cell: ({ row }) => {
      return (
        <p className='opacity-50'>{row.original.referrals.length || 0} users</p>
      )
    }
  },
  {
    accessorKey: 'refer_earnings_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Total Earned' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-green550'>
          {Number(msatsToSats(row.original.refer_earnings_msats)) +
            Number(msatsToSats(row.original.refer_claimable_msats))}{' '}
          sats
        </p>
      )
    }
  }
]
