import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'

import { IReferralTransaction } from '@/types/account/referral.interface'

import formatId from '@/utils/formatId'

import { msatsToSats } from '@/utils'

export const columns: ColumnDef<IReferralTransaction>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'display_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Display Name' />
    },
    cell: ({ row }) => {
      if (row.original.account.display_name) {
        return <p>{row.original.account.display_name}</p>
      } else {
        return <p className='opacity-50'>-</p>
      }
    },
    enableSorting: false
  },
  {
    accessorKey: 'legal_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Legal Name' />
    },
    cell: ({ row }) => {
      if (row.original.account.first_name && row.original.account.last_name) {
        return (
          <p>
            {row.original.account.first_name} {row.original.account.last_name}
          </p>
        )
      } else {
        return <p className='min-w-28 opacity-50'>Not Verified</p>
      }
    },
    enableSorting: false
  },
  {
    accessorKey: 'username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.account.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='opacity-50'
        >
          @{row.original.account.username}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.account.email)
            toast.success('Email copied to clipboard')
          }}
          className='opacity-50'
        >
          {row.original.account.email}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'amount_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-purple400'>
          {msatsToSats(row.original.amount_msats).toString()} sats
        </p>
      )
    }
  },
  {
    accessorKey: 'invoice',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Invoice' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.invoice || 'No Invoice')
            toast.success('Invoice Copied to clipboard')
          }}
          className='opacity-50'
        >
          {row.original.invoice || 'No Invoice'}
        </button>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return <p>{new Date(row.original.created_at).toLocaleString()}</p>
    }
  }
]
