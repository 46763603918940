import React from 'react'

import { cn } from '@/utils'

export function View({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div className={cn('h-full w-full bg-gray900', className)}>{children}</div>
  )
}
