import ProgressBar from '@/components/ProgressBar'

import {
  ExchangeDataInterface,
  FlashDataInterface,
  IUserMetrics,
  StarterDataInterface,
  VolumesDataInterface
} from '@/types/metrics/metrics.interface'

import {
  EMPTY_NUMBER,
  formatInteger,
  formatNumber,
  msatsToUSD,
  mstasToBtcAndFormat
} from '@/utils'

const formatUserData = (data?: IUserMetrics) => {
  if (!data) {
    return {
      dailyActive: {
        label: EMPTY_NUMBER,
        description: 'Daily Active'
      },
      weeklyActive: {
        label: EMPTY_NUMBER,
        description: 'Weekly Active'
      },
      monthlyActive: {
        label: EMPTY_NUMBER,
        description: 'monthly Active'
      },
      totalAccounts: {
        label: EMPTY_NUMBER,
        description: 'Total Accounts'
      },
      satoshiPlus: {
        label: EMPTY_NUMBER,
        description: 'Satoshi+'
      },
      referredUsers: {
        label: EMPTY_NUMBER,
        description: 'Referred Users'
      },
      waitlistEmails: {
        label: EMPTY_NUMBER,
        description: 'Waitlist Emails'
      },
      totalDownloads: {
        label: EMPTY_NUMBER,
        description: 'Total Downloads'
      }
    }
  }

  return {
    dailyActive: {
      label: formatInteger(data?.users_daily_active, EMPTY_NUMBER),
      description: 'Daily Active'
    },
    weeklyActive: {
      label: formatInteger(data?.users_weekly_active, EMPTY_NUMBER),
      description: 'Weekly Active'
    },
    monthlyActive: {
      label: formatInteger(data?.users_monthly_active, EMPTY_NUMBER),
      description: 'monthly Active'
    },
    totalAccounts: {
      label: formatInteger(data?.users_total, EMPTY_NUMBER),
      description: 'Total Accounts'
    },
    satoshiPlus: {
      label: formatInteger(data?.users_satoshi_plus, EMPTY_NUMBER),
      description: 'Satoshi+'
    },
    referredUsers: {
      label: formatInteger(data?.users_referred, EMPTY_NUMBER),
      description: 'Referred Users'
    },
    waitlistEmails: {
      label: formatInteger(data?.users_waitlisted_emails, EMPTY_NUMBER),
      description: 'Waitlist Emails'
    },
    totalDownloads: {
      label: formatInteger(data?.users_total_downloads, EMPTY_NUMBER),
      description: 'Total Downloads'
    }
  }
}

const formatExchangeData = (data: ExchangeDataInterface | undefined) => {
  return {
    bitcoinUnderCustody: {
      description: 'Bitcoin Under Custody',
      tabs: [
        {
          key: 'current',
          label: 'Current',
          value: `${mstasToBtcAndFormat(data?.custodied_msats)} BTC`
        }
      ]
    },
    dollarsUnderCustody: {
      description: 'Dollars Under Custody',
      tabs: [
        {
          key: 'current',
          label: 'Current',
          value: `$${formatNumber(data?.custodied_usd, EMPTY_NUMBER)}`
        }
      ]
    },
    buyVolume: {
      description: 'Buy Volume',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `$${formatNumber(data?.buy_volume_usd_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `$${formatNumber(data?.buy_volume_usd_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `$${formatNumber(data?.buy_volume_usd_all, EMPTY_NUMBER)}`
        }
      ]
    },
    sellVolume: {
      description: 'Sell Volume',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `$${formatNumber(data?.sell_volume_usd_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `$${formatNumber(data?.sell_volume_usd_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `$${formatNumber(data?.sell_volume_usd_all, EMPTY_NUMBER)}`
        }
      ]
    }
  }
}

const formatFlashData = (data: FlashDataInterface | undefined) => {
  return {
    totalLightningBalances: {
      label: `$${formatNumber(
        msatsToUSD(
          data?.total_lightning_balance_msats,
          data?.btcPrice ?? 0
        ).toString(),
        EMPTY_NUMBER
      )}`,
      description: `${mstasToBtcAndFormat(data?.total_lightning_balance_msats)} BTC`,
      subDescription: 'Total Lightning Balances'
    },
    volumeReceived: {
      label: `$${formatNumber(
        msatsToUSD(data?.volume_received_msats, data?.btcPrice ?? 0).toString(),
        EMPTY_NUMBER
      )}`,
      description: `${mstasToBtcAndFormat(data?.volume_received_msats)} BTC`,
      subDescription: 'Volume Received'
    },
    volumeSent: {
      label: `$${formatNumber(
        msatsToUSD(data?.volume_sent_msats, data?.btcPrice ?? 0).toString(),
        EMPTY_NUMBER
      )}`,
      description: `${mstasToBtcAndFormat(data?.volume_sent_msats)} BTC`,
      subDescription: 'Volume Sent'
    },
    paymentsMade: {
      description: 'Payments Made',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.payments_made_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.payments_made_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.payments_made_all, EMPTY_NUMBER)}`
        }
      ]
    }
  }
}

const formatStarterData = (inputData: StarterDataInterface | undefined) => {
  const data = inputData?.result

  return {
    totalBalance: {
      label: `$${formatNumber(data?.starter_total_balance_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.starter_total_balance_msats)} BTC`,
      subDescription: 'Total Balance'
    },
    volumeReceived: {
      label: `$${formatNumber(data?.starter_volume_received_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.starter_volume_received_msats)} BTC`,
      subDescription: 'Volume Received'
    },
    volumeSent: {
      label: `$${formatNumber(data?.starter_volume_sent_usd, EMPTY_NUMBER)}`,
      description: `${mstasToBtcAndFormat(data?.starter_volume_sent_msats)} BTC`,
      subDescription: 'Volume Sent'
    },
    paymentsMade: {
      description: 'Payments Made',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.starter_payments_made_24h, EMPTY_NUMBER)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.starter_payments_made_30d, EMPTY_NUMBER)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.starter_payments_made_all, EMPTY_NUMBER)}`
        }
      ]
    }
  }
}

const formatVolumesData = (inputData: VolumesDataInterface | undefined) => {
  return {
    funding: {
      deposit: {
        description: 'Deposit volume',
        tabs: [
          {
            key: 'week',
            label: 'Week',
            value: (
              <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
                <span>
                  ${formatNumber(inputData?.funding.deposit.week, EMPTY_NUMBER)}
                  {' / '}$
                  {formatNumber(
                    inputData?.limits.funding_deposit_weekly_limit,
                    EMPTY_NUMBER
                  )}
                </span>
                <ProgressBar
                  value={
                    Number(inputData?.funding.deposit.week ?? 1) /
                    Number(inputData?.limits.funding_deposit_weekly_limit ?? 1)
                  }
                  color='blue500'
                />
              </div>
            )
          },
          {
            key: 'month',
            label: 'Month',
            value: (
              <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
                <span>
                  $
                  {formatNumber(inputData?.funding.deposit.month, EMPTY_NUMBER)}
                  {' / '}$
                  {formatNumber(
                    inputData?.limits.funding_deposit_monthly_limit,
                    EMPTY_NUMBER
                  )}
                </span>
                <ProgressBar
                  value={
                    Number(inputData?.funding.deposit.month ?? 1) /
                    Number(inputData?.limits.funding_deposit_monthly_limit ?? 1)
                  }
                  color='blue500'
                />
              </div>
            )
          }
        ]
      },
      withdrawal: {
        description: 'Withdrawal Volume',
        tabs: [
          {
            key: 'week',
            label: 'Week',
            value: (
              <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
                <span>
                  $
                  {formatNumber(
                    inputData?.funding.withdrawal.week,
                    EMPTY_NUMBER
                  )}
                  {' / '}$
                  {formatNumber(
                    inputData?.limits.funding_withdrawal_weekly_limit,
                    EMPTY_NUMBER
                  )}
                </span>
                <ProgressBar
                  value={
                    Number(inputData?.funding.withdrawal.week ?? 1) /
                    Number(
                      inputData?.limits.funding_withdrawal_weekly_limit ?? 1
                    )
                  }
                  color='blue500'
                />
              </div>
            )
          },
          {
            key: 'month',
            label: 'Month',
            value: (
              <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
                <span>
                  $
                  {formatNumber(
                    inputData?.funding.withdrawal.month,
                    EMPTY_NUMBER
                  )}
                  {' / '}$
                  {formatNumber(
                    inputData?.limits.funding_withdrawal_monthly_limit,
                    EMPTY_NUMBER
                  )}
                </span>
                <ProgressBar
                  value={
                    Number(inputData?.funding.withdrawal.month ?? 1) /
                    Number(
                      inputData?.limits.funding_withdrawal_monthly_limit ?? 1
                    )
                  }
                  color='blue500'
                />
              </div>
            )
          }
        ]
      }
    },
    trading: {
      description: 'Trading Volume',
      tabs: [
        {
          key: 'week',
          label: 'Week',
          value: (
            <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-orange550 lg:text-2xl'>
              <span>
                ${formatNumber(inputData?.trading.week, EMPTY_NUMBER)}
                {' / '}$
                {formatNumber(
                  inputData?.limits.trade_weekly_limit,
                  EMPTY_NUMBER
                )}
              </span>
              <ProgressBar
                value={
                  Number(inputData?.trading.week ?? 1) /
                  Number(inputData?.limits.trade_weekly_limit ?? 1)
                }
                color='orange550'
              />
            </div>
          )
        },
        {
          key: 'month',
          label: 'Month',
          value: (
            <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-orange550 lg:text-2xl'>
              <span>
                ${formatNumber(inputData?.trading.month, EMPTY_NUMBER)}
                {' / '}$
                {formatNumber(
                  inputData?.limits.trade_monthly_limit,
                  EMPTY_NUMBER
                )}
              </span>
              <ProgressBar
                value={
                  Number(inputData?.trading.month ?? 1) /
                  Number(inputData?.limits.trade_monthly_limit ?? 1)
                }
                color='orange550'
              />
            </div>
          )
        }
      ]
    }
  }
}

export const formatMetricsData = ({
  userData,
  exchange,
  flash,
  starter,
  volumes
}: {
  userData?: IUserMetrics
  exchange?: ExchangeDataInterface
  flash?: FlashDataInterface
  starter?: StarterDataInterface
  volumes?: VolumesDataInterface
}) => {
  return {
    userData: formatUserData(userData),
    exchange: formatExchangeData(exchange),
    flash: formatFlashData(flash),
    starter: formatStarterData(starter),
    volumes: formatVolumesData(volumes)
  }
}
