import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { DrawerPosition, DrawerWrapper } from '@/components/Drawer/DrawerWrapper'

interface DrawerProps {
  children?: ReactNode | ReactNode[]
  open?: boolean
  onClose?: () => void
  position?: DrawerPosition
  contentClassName?: string
  wrapperClassName?: string
}

export const Drawer = ({
  children,
  position = 'left',
  contentClassName,
  wrapperClassName,
  open: propsOpen,
  onClose: propsOnClose
}: DrawerProps) => {
  const [open, setOpen] = useState<boolean>(!!propsOpen)

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : ''
  }, [open])

  useEffect(() => {
    setOpen(!!propsOpen)
  }, [propsOpen])

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClose()
      }
    }
    document.addEventListener('keyup', handler)
    return () => {
      document.removeEventListener('keyup', handler)
    }
  }, [])

  const onClose = () => {
    setOpen(false)
    propsOnClose && propsOnClose()
  }

  return createPortal(
    <DrawerWrapper
      contentClassName={contentClassName}
      wrapperClassName={wrapperClassName}
      open={open}
      onClose={onClose}
      position={position}
    >
      {children}
    </DrawerWrapper>,
    document.body
  )
}
