import { Button } from '@/components/DataTable/Button'
import * as ICONS from '@/components/icons'

import { cn } from '@/utils'

export type IconType = keyof typeof ICONS

interface Props {
  icon: IconType
  className?: string
  onClick?: () => void
}

const Icon: React.FC<Props> = ({ icon, className, onClick }) => {
  const Component = ICONS[icon as IconType] || ICONS['Bitcoin']

  if (onClick) {
    return (
      <Button onClick={onClick} size={'icon'} variant={'link'}>
        <Component className={cn('size-4', className)} />
      </Button>
    )
  }

  return <Component className={cn('size-8', className)} />
}

export default Icon
