import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import api from '@/api'
import { errorCatch } from '@/api/error'
import { DeleteButton } from '@/components/Buttons/DeleteButton'

interface Props {
  id: string
}

const DeleteRole: React.FC<Props> = ({ id }) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: ['deleteVideo'],
    mutationFn: async (id: string) => {
      await api.delete(`/admin/roles/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || 'Some error happend. Please try again')
    }
  })

  return <DeleteButton title='Delete' onDelete={() => mutate(id)} />
}

export default DeleteRole
