export default function Approved({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
    >
      <g
        id='Group_4111'
        data-name='Group 4111'
        transform='translate(-1020 -462)'
      >
        <g
          id='Group_2198'
          data-name='Group 2198'
          transform='translate(1020 462)'
        >
          <rect
            id='BTCB'
            width='20'
            height='20'
            rx='10'
            transform='translate(0 0)'
            fill='#45c925'
          />
        </g>
        <g
          id='Group_2296'
          data-name='Group 2296'
          transform='translate(1025.055 467.785)'
        >
          <rect
            id='Rectangle_4757'
            data-name='Rectangle 4757'
            width='5.566'
            height='1.867'
            rx='0.933'
            transform='translate(0.962 3.868) rotate(31)'
            fill='#fff'
          />
          <rect
            id='Rectangle_4758'
            data-name='Rectangle 4758'
            width='8.868'
            height='1.867'
            rx='0.933'
            transform='matrix(0.574, -0.819, 0.819, 0.574, 3.274, 7.264)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}
