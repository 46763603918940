import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { Button } from '@/components/DataTable/Button'
import ProtectedComponent from '@/components/ProtectedComponent'
import { ActionButton } from '@/components/UserDetails/ActionButton'
import { Section, SectionOption } from '@/components/UserDetails/Section'

import { IUser } from '@/types/account/user.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  noTitle?: boolean
  user: IUser
}

const UserActions: React.FC<Props> = ({ user, noTitle = false }) => {
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['update-user', user.id],
    mutationFn: async (params: {
      url: string
      method: string
      errorTitle: string
      successMessage: string
    }) => {
      const resp = await api(params.url, {
        method: params.method
      })

      return resp.data
    },
    onError(error, context) {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || context.errorTitle)
    },
    onSuccess(data, context) {
      queryClient.invalidateQueries({
        queryKey: ['user', user.id]
      })
      if (context.successMessage === 'OTP') {
        const toastId = toast.success(
          <div className='flex flex-col gap-2'>
            <p>
              OTP sent to @{user?.username} successfully.
              <br />
              OTP: <b className='text-lg font-bold'>{data}</b>
              <br />
              Click close to copy the OTP.
            </p>
            <Button
              variant={'destructive'}
              onClick={() => {
                navigator.clipboard.writeText(data)
                toast.dismiss(toastId)
              }}
              className='self-end'
            >
              Close
            </Button>
          </div>,
          {
            position: 'top-center',
            duration: 100000
          }
        )
      } else {
        toast.success(context.successMessage)
      }
    }
  })

  const handleBlock = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-block`,
      method: 'PUT',
      errorTitle: user?.blocked
        ? 'An error occurred while unblocking the user.'
        : 'An error occurred while blocking the user.',
      successMessage: user?.blocked ? 'User unblocked.' : 'User blocked.'
    })
  }

  const handleBlockIps = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-block-ip`,
      method: 'PUT',
      errorTitle: user?.is_all_ip_addresses_blocked
        ? 'An error occurred while unblocking the user IP addresses.'
        : 'An error occurred while blocking the user IP addresses.',
      successMessage: user?.blocked
        ? 'User IP addresses unblocked.'
        : 'User IP addresses blocked.'
    })
  }

  const handleDelete = async () => {
    mutate({
      url: `/admin/users/${user.id}/handle-delete`,
      method: 'PUT',
      errorTitle: user?.is_deleted
        ? 'An error occurred while restoring the user.'
        : 'An error occurred while soft deleting the user.',
      successMessage: user?.is_deleted ? 'User restored.' : 'User soft deleted.'
    })
  }

  const handleWithdrawal = async () => {
    mutate({
      url: `admin/users/${user.id}/handle-withdrawal`,
      method: 'PUT',
      errorTitle: user?.withdrawals_paused
        ? 'An error occurred while resuming withdrawals.'
        : 'An error occurred while pausing withdrawals.',
      successMessage: user?.withdrawals_paused
        ? 'Withdrawals resumed.'
        : 'Withdrawals paused.'
    })
  }

  const handleResetTwoFactor = async () => {
    mutate({
      url: `admin/users/${user.id}/reset-2fa`,
      method: 'PUT',
      errorTitle: 'An error occurred while resetting the 2FA.',
      successMessage: '2FA reset.'
    })
  }

  const handleSendOtp = async () => {
    mutate({
      url: `admin/users/${user.id}/send-otp`,
      method: 'PUT',
      errorTitle: 'An error occurred while sending the OTP.',
      successMessage: 'OTP'
    })
  }
  return (
    <Section
      title={noTitle ? undefined : 'Actions'}
      loading={isPending}
      options={
        [
          user?.avatar && {
            label: 'Remove Avatar',
            rightContent: (
              <ProtectedComponent permissionSlug='users_update'>
                <ActionButton
                  onClick={() =>
                    mutate({
                      url: `/admin/users/${user.id}/update-avatar`,
                      method: 'PATCH',
                      errorTitle:
                        'An error occurred while removing the avatar.',
                      successMessage: 'Avatar removed.'
                    })
                  }
                  title='Remove'
                />
              </ProtectedComponent>
            )
          },
          user?.blocked
            ? {
                label: 'Unblock Account',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_block'>
                    <ActionButton onClick={handleBlock} title='Unblock' />
                  </ProtectedComponent>
                )
              }
            : {
                label: 'Block Account',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_block'>
                    <ActionButton onClick={handleBlock} title='Block' />
                  </ProtectedComponent>
                )
              },
          user.ip_addresses && user.ip_addresses.length > 0
            ? user?.is_all_ip_addresses_blocked
              ? {
                  label: "Unblock IP's",
                  rightContent: (
                    <ProtectedComponent permissionSlug='users_handle_block'>
                      <ActionButton onClick={handleBlockIps} title='Unblock' />
                    </ProtectedComponent>
                  )
                }
              : {
                  label: "Block IP's",
                  rightContent: (
                    <ProtectedComponent permissionSlug='users_handle_block'>
                      <ActionButton onClick={handleBlockIps} title='Block' />
                    </ProtectedComponent>
                  )
                }
            : null,
          user?.is_two_factor_enabled && {
            label: 'Reset 2FA',
            rightContent: (
              <ProtectedComponent permissionSlug='users_handle_block'>
                <ActionButton onClick={handleResetTwoFactor} title='Reset' />
              </ProtectedComponent>
            )
          },
          {
            label: 'Send OTP',
            rightContent: (
              <ProtectedComponent permissionSlug='users_handle_block'>
                <ActionButton onClick={handleSendOtp} title='Send' />
              </ProtectedComponent>
            )
          },
          user?.withdrawals_paused
            ? {
                label: 'Resume Withdraw',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_withdraw'>
                    <ActionButton title='Resume' onClick={handleWithdrawal} />
                  </ProtectedComponent>
                )
              }
            : {
                label: 'Pause Withdraw',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_handle_withdraw'>
                    <ActionButton title='Pause' onClick={handleWithdrawal} />
                  </ProtectedComponent>
                )
              },
          user?.is_deleted
            ? {
                label: 'Restore Account',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_soft_delete'>
                    <ActionButton title='Restore' onClick={handleDelete} />
                  </ProtectedComponent>
                )
              }
            : {
                label: 'Delete (soft)',
                rightContent: (
                  <ProtectedComponent permissionSlug='users_soft_delete'>
                    <ActionButton title='Delete' onClick={handleDelete} />
                  </ProtectedComponent>
                )
              }
        ].filter(Boolean) as SectionOption[]
      }
    />
  )
}

export default UserActions
