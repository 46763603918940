export const permissionGroups = [
  {
    name: 'User Actions',
    permissions: [
      'users_view',
      'users_update',
      'users_handle_block',
      'users_reset_pin',
      'users_reset_2fa',
      'users_reset_biometric',
      'users_send_otp',
      'users_handle_withdraw',
      'users_soft_delete',
      'users_send_notifications'
    ]
  },
  {
    name: 'Uesrname Blacklist',
    permissions: ['username_blacklist_view', 'username_blacklist_update']
  },
  {
    name: 'Learn',
    permissions: ['learn_create', 'learn_update', 'learn_delete']
  },
  {
    name: 'Metrics',
    permissions: ['metrics_view']
  },
  {
    name: 'Bank',
    permissions: [
      'kyc_verifications_view',
      'kyc_verifications_unblock',
      'bank_connection_view',
      'bank_transfers_view',
      'bank_fundings_view'
    ]
  },
  {
    name: 'EDD Requests',
    permissions: [
      'edd_requests_view',
      'edd_requests_create',
      'edd_requests_approve'
    ]
  },
  {
    name: 'Account Groups',
    permissions: [
      'account_groups_view',
      'account_groups_create',
      'account_groups_update',
      'account_groups_delete'
    ]
  },
  {
    name: 'Organization',
    permissions: [
      'organization_view',
      'admins_create',
      'admins_update',
      'admins_delete',
      'admins_reset_password',
      'roles_create',
      'roles_update',
      'roles_delete',
      'configurations_update'
    ]
  }
]
