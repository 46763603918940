import { IStarterLimits } from '@/types/account/limits.interface'

interface Props {
  limits: IStarterLimits
}

const StarterLimits: React.FC<Props> = ({ limits }) => {
  return (
    <section className='flex flex-col gap-2'>
      <h2 className='text-xl font-bold'>Starter Wallet Limits</h2>

      <div className='flex flex-col gap-1'>
        <h3 className='text-lg font-semibold'>Balance</h3>
        <p className='text-foreground/50'>
          Total Balance USD:{' '}
          <b className='text-foreground'>
            ${limits.total_balance_usd.toLocaleString()}
          </b>
        </p>
      </div>

      <div className='flex flex-col gap-1'>
        <h3 className='text-lg font-semibold'>Onchain Transactions</h3>
        <p className='text-foreground/50'>
          Send Limit USD:{' '}
          <b className='text-foreground'>
            ${limits.send_onchain_usd.toLocaleString()}
          </b>
        </p>
        <p className='text-foreground/50'>
          Receive Limit USD:{' '}
          <b className='text-foreground'>
            ${limits.receive_onchain_usd.toLocaleString()}
          </b>
        </p>
        <p className='text-foreground/50'>
          Daily Send Volume USD:{' '}
          <b className='text-foreground'>
            ${limits.daily_send_onchain_volume_usd.toLocaleString()}
          </b>
        </p>
        <p className='text-foreground/50'>
          Daily Receive Volume USD:{' '}
          <b className='text-foreground'>
            ${limits.daily_receive_onchain_volume_usd.toLocaleString()}
          </b>
        </p>
      </div>

      <div className='flex flex-col gap-1'>
        <h3 className='text-lg font-semibold'>Lightning Transactions</h3>
        <p className='text-foreground/50'>
          Send Limit USD:{' '}
          <b className='text-foreground'>
            ${limits.send_lightning_usd.toLocaleString()}
          </b>
        </p>
        <p className='text-foreground/50'>
          Receive Limit USD:{' '}
          <b className='text-foreground'>
            ${limits.receive_lightning_usd.toLocaleString()}
          </b>
        </p>
        <p className='text-foreground/50'>
          Daily Send Volume USD:{' '}
          <b className='text-foreground'>
            ${limits.daily_send_lightning_volume_usd.toLocaleString()}
          </b>
        </p>
        <p className='text-foreground/50'>
          Daily Receive Volume USD:{' '}
          <b className='text-foreground'>
            ${limits.daily_receive_lightning_volume_usd.toLocaleString()}
          </b>
        </p>
      </div>
    </section>
  )
}

export default StarterLimits
