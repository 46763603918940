import React, {
  CSSProperties,
  ForwardedRef,
  ReactNode,
  forwardRef
} from 'react'

import { cn } from '@/utils'

interface ButtonProps {
  title: string | ReactNode
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
  style?: CSSProperties
  type?: 'button' | 'submit'
}

export const Button = forwardRef(
  (
    {
      onClick,
      title,
      disabled,
      className,
      style,
      type = 'button'
    }: ButtonProps,
    ref: ForwardedRef<any>
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={cn(
          'inline-flex items-center rounded-[30px] bg-opacity-10 px-[15px] py-[8px] hover:opacity-70 active:opacity-50',
          className,
          {
            'cursor-not-allowed opacity-30': disabled
          }
        )}
        style={style}
      >
        {title}
      </button>
    )
  }
)

Button.displayName = 'Button'
