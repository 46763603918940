import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { tabs } from '@/routes/Learn/Tabs'

const Learn = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Object.keys(tabs).includes(location.pathname))
      navigate(Object.keys(tabs)[0], { replace: true })
  }, [location.pathname, navigate])

  return <></>
}

export default Learn
