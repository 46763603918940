export default function Refer({ className }: { className: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={className}
    >
      <g id='Group_4411' data-name='Group 4411' transform='translate(-70 -647)'>
        <g
          id='Rectangle_7844'
          data-name='Rectangle 7844'
          transform='translate(70 647)'
          fill='none'
          className='stroke-current'
          strokeWidth='2'
        >
          <rect width='24' height='24' rx='12' stroke='none' />
          <rect x='1' y='1' width='22' height='22' rx='11' fill='none' />
        </g>
        <g id='Rectangle_7845_Group' transform='translate(75 657)' fill='none'>
          <rect
            id='Rectangle_7845'
            data-name='Rectangle 7845'
            width='14'
            height='14'
            rx='7'
            fill='currentColor'
          />
        </g>
      </g>
    </svg>
  )
}
