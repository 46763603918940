import { DataTable } from '@/components/DataTable'

import { columns } from './columns'

export const ReferUsers = () => {
  return (
    <DataTable
      columns={columns}
      url='/admin/users'
      totalText='users'
      tableClassName='md:!max-h-[calc(100vh-550px)]'
      method='POST'
      defaultSortBy='referrals'
      defaultSortOrder='desc'
    />
  )
}
