import React from 'react'

import { Button } from '@/components/Button'

import { cn } from '@/utils'

interface AddButtonProps {
  title: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
}

export const AddButton = ({
  title,
  onClick,
  className,
  disabled
}: AddButtonProps) => {
  return (
    <Button
      title={title}
      disabled={disabled}
      onClick={onClick}
      className={cn(
        'h-10 justify-center bg-orange550/10 px-[20px] text-center text-[12px] font-bold text-orange550 transition-all',
        className
      )}
    />
  )
}
