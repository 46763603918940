import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import ProtectedComponent from '@/components/ProtectedComponent'

import ReferMetrics from './Metrics'
import Tabs, { tabs } from './Tabs'

export function Refer() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Object.keys(tabs).includes(location.pathname))
      navigate(Object.keys(tabs)[0], { replace: true })
  }, [location.pathname, navigate])

  return (
    <ProtectedComponent permissionSlug='metrics_view' isPage>
      <Helmet>
        <title>{`STBA Admin - Refer`}</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white/15 pb-7 text-2xl font-bold text-white md:text-3xl'>
          Refer
        </h1>
        <ReferMetrics />
        <Tabs />
        <Outlet />
      </section>
    </ProtectedComponent>
  )
}
