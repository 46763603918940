import { Helmet } from 'react-helmet'

import { DataTable } from '@/components/DataTable'
import ProtectedComponent from '@/components/ProtectedComponent'

import CreateMany from './CreateMany'
import CreateOrUpdate from './CreateOrUpdate'
import { columns } from './columns'

export function UsernameBlacklist() {
  return (
    <ProtectedComponent permissionSlug='username_blacklist_view' isPage>
      <Helmet>
        <title>STBA Admin - Username Blacklist</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <section className='flex w-full flex-wrap justify-between border-b border-white/15 pb-7'>
          <h1 className='text-2xl font-bold text-white md:text-3xl'>
            Username Blacklist
          </h1>
          <ProtectedComponent permissionSlug='username_blacklist_update'>
            <div className='flex flex-row gap-2'>
              <CreateOrUpdate />
              <CreateMany />
            </div>
          </ProtectedComponent>
        </section>

        <DataTable
          columns={columns}
          url='/admin/username-blacklist'
          totalText='usernames'
        />
      </section>
    </ProtectedComponent>
  )
}
