import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import api from '@/api'
import { ResultWithPagination } from '@/components/DataTable/DataTable.types'
import MultipleAutocomplete from '@/components/MultipleAutocomplete'
import { ICategory } from '@/types/learn/category.interface'

interface Props {
  initial?: { label: string; value: string }[]
  categories: string[]
  setCategories: (categories: string[]) => void
}

const CategoriesSelect: React.FC<Props> = ({
  initial,
  categories,
  setCategories
}) => {
  const [search, setSearch] = useState<string>('')

  const { data, isLoading } = useQuery({
    queryKey: ['categories', search],
    queryFn: async () => {
      const response = await api<ResultWithPagination<ICategory>>(
        'admin/learn/categories',
        {
          method: 'GET',
          params: {
            search
          }
        }
      )
      if (response.data.result && response.data.result.length) {
        const result = response.data.result.map(item => ({
          label: item.name,
          value: item.id
        }))
        if (initial) {
          initial.forEach(item => {
            if (!result.find(i => i.value === item.value)) {
              result.unshift(item)
            }
          })
        }
        return result
      }
    }
  })

  return (
    <MultipleAutocomplete
      search={search}
      setSearch={setSearch}
      values={categories}
      setValues={setCategories}
      data={data || []}
      title='Select Categories'
      loading={isLoading}
    />
  )
}

export default CategoriesSelect
