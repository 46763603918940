import { cn } from '.'

import Icon from '@/components/Icon'

import { KycStatusEnum } from '@/types/account/user.interface'

export const returnKycIcon = (kycStatus: KycStatusEnum, className?: string) => {
  return (
    <Icon
      icon={
        kycStatus === KycStatusEnum.UNVERIFIED
          ? 'Unverified'
          : kycStatus === KycStatusEnum.FAILED
            ? 'Rejected'
            : kycStatus === KycStatusEnum.STARTED
              ? 'InProgress'
              : 'Approved'
      }
      className={cn('size-6', className)}
    />
  )
}
