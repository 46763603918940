import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import Icon from '@/components/Icon'
import styles from '@/components/Popup/popup.module.css'

import { cn } from '@/utils'

type VerticalPosition = 'top' | 'middle' | 'bottom'
type HorizontalPosition = 'start' | 'middle' | 'end'

export interface PopupProps {
  open?: boolean
  trigger?: 'click' | 'hover'
  onClose?: () => void
  onOpen?: () => void
  content?: ReactNode | ReactNode[]
  title?: ReactNode | string
  children: JSX.Element
  verticalPosition?: VerticalPosition
  horizontalPosition?: HorizontalPosition
  className?: string
}

const ANIMATION_DURATION = 300
export const Popup = ({
  open: propsOpen,
  onOpen: propsOnOpen,
  verticalPosition = 'top',
  horizontalPosition = 'middle',
  trigger,
  onClose: propsOnClose,
  children,
  content,
  title,
  className
}: PopupProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(!!propsOpen)
  const [hide, setHide] = useState<boolean>(!open)
  const [xStyles, setXStyles] = useState({
    start: {
      right: '',
      left: '0',
      translateX: '-100%'
    },
    middle: {
      right: '',
      left: '50%',
      translateX: '-50%'
    },
    end: {
      right: '100%',
      left: '',
      translateX: '0'
    }
  })
  const [yStyles, setYStyles] = useState({
    top: {
      top: '0',
      translateY: '-100%'
    },
    middle: {
      top: '50%',
      translateY: '-50%'
    },
    bottom: {
      top: '100%',
      translateY: '0'
    }
  })

  useEffect(() => {
    const container = ref.current
    if (!container) {
      return
    }

    const handler = (e: WheelEvent | TouchEvent) => {
      e.stopPropagation()
      e.preventDefault()
    }
    container.addEventListener('wheel', handler)
    container.addEventListener('touchmove', handler)

    return () => {
      container.removeEventListener('wheel', handler)
      container.removeEventListener('touchmove', handler)
    }
  }, [ref.current])

  useEffect(() => {
    setOpen(!!propsOpen)
    setHide(!propsOpen)
  }, [propsOpen])

  const onClose = () => {
    setHide(true)
    setTimeout(() => {
      setOpen(false)
      propsOnClose && propsOnClose()
      setHide(false)
    }, ANIMATION_DURATION)
  }

  const onOpen = () => {
    propsOnOpen && propsOnOpen()
    setOpen(true)
    setHide(false)
  }

  const setupMarkup = (element?: HTMLElement) => {
    if (!element) {
      return
    }
    const updateStyles = () => {
      const { left, right, top, width, height } =
        element.getBoundingClientRect()

      setXStyles({
        start: {
          right: '',
          left: `${left}px`,
          translateX: `-100%`
        },
        middle: {
          right: '',
          left: `${left + width / 2}px`,
          translateX: `-50%`
        },
        end: {
          right: `${window.innerWidth - right}px`,
          left: ``,
          translateX: '0px'
        }
      })
      setYStyles({
        top: {
          top: `${top}px`,
          translateY: `-100%`
        },
        middle: {
          top: `${top + height / 2}px`,
          translateY: `-50%`
        },
        bottom: {
          top: `${top + height}px`,
          translateY: '0px'
        }
      })
    }

    document.querySelectorAll('*').forEach(item => {
      // @ts-expect-error - we know that item is an HTMLElement
      item.onscroll = () => {
        updateStyles()
      }
    })

    updateStyles()
  }

  const parent = useMemo(() => {
    return React.cloneElement(children, {
      onClick: trigger === 'click' ? (open ? onClose : onOpen) : undefined,
      onMouseEnter: trigger === 'hover' ? onOpen : undefined,
      onMouseLeave: trigger === 'hover' ? onClose : undefined,
      style: { position: 'relative' },
      ref: (element: HTMLElement) => {
        setupMarkup(element)
      }
    })
  }, [children, trigger, open])

  return (
    <>
      {parent}
      {open &&
        createPortal(
          <div
            ref={ref}
            onClick={e => {
              e.stopPropagation()
              onClose()
            }}
            className='fixed left-0 top-0 z-999 h-full w-full'
          >
            <div
              className={cn(
                'fixed rounded-[30px] border-2 border-gray500/20 bg-gray800 p-[30px]',
                className,
                {
                  [styles.popupContainerVisible]: !hide,
                  [styles.popupContainerHidden]: hide
                }
              )}
              style={{
                top: yStyles[verticalPosition].top,
                left: xStyles[horizontalPosition].left,
                right: xStyles[horizontalPosition].right,
                transform: `translate(${xStyles[horizontalPosition].translateX}, ${yStyles[verticalPosition].translateY})`,
                animationDuration: `${ANIMATION_DURATION}ms`,
                zIndex: 999
              }}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {typeof title === 'string' ? (
                <div className='flex items-center justify-between gap-[16px]'>
                  <p className='text-[20px] font-bold text-white'>{title}</p>
                  <Icon
                    icon='Close'
                    onClick={onClose}
                    className='cursor-pointer fill-foreground opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
                  />
                </div>
              ) : (
                title
              )}
              {content}
            </div>
          </div>,
          document.body
        )}
    </>
  )
}
