import { Switch } from '@nextui-org/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import { EditButton } from '@/components/Buttons/EditButton'
import { Drawer } from '@/components/Drawer'
import { FormButton } from '@/components/FormButton'
import Icon from '@/components/Icon'
import { Input } from '@/components/Input'
import { Loader } from '@/components/Loader'
import { ErrorMessage } from '@/components/Message/ErrorMessage'

import { IPermission } from '@/types/roles/permission.inteface'
import { ICreateOrUpdateRole, IRole } from '@/types/roles/role.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'
import { permissionGroups } from '@/constants/permission-groups'

interface Props {
  initial?: IRole
}

const CreateOrUpdateRole: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { data } = useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const resp = await api<IPermission[]>('/admin/roles/permissions')
      return resp.data
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateRole>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          name: initial.name,
          description: initial.description,
          permissionsIds: initial.permissions.map(permission => permission.id)
        }
      : {
          name: '',
          description: '',
          permissionsIds: []
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            name: initial.name,
            description: initial.description,
            permissionsIds: initial.permissions.map(permission => permission.id)
          }
        : {
            name: '',
            description: '',
            permissionsIds: []
          }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-role' : 'add-role'],
    mutationFn: (data: ICreateOrUpdateRole) =>
      api(initial ? `/admin/roles/${initial.id}` : '/admin/roles', {
        method: initial ? 'PATCH' : 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(`Role ${initial ? 'updated' : 'created'} successfully!`)
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} the role.`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateRole> = async data =>
    mutate(data)

  return (
    <>
      {initial ? (
        <EditButton onClick={() => setIsOpen(true)} title='Edit' />
      ) : (
        <AddButton onClick={() => setIsOpen(true)} title='Add Role' />
      )}
      <Drawer
        contentClassName='bg-gray800 border-solid border-gray500/50 w-full sm:w-[480px]'
        open={isOpen}
        position='right'
        onClose={() => setIsOpen(false)}
      >
        <Loader loading={isPending} type='absolute' />
        <div className='flex h-full flex-col gap-[40px] overflow-y-auto p-[24px] sm:px-[70px] sm:py-[60px]'>
          <div className='flex flex-row items-center justify-between'>
            <p
              title={initial ? initial.name : 'Add Role'}
              className='long-text whitespace-nowrap pr-[16px] text-[24px] font-bold text-white'
            >
              {initial ? initial.name : 'Add Role'}
            </p>
            <Icon
              icon='Close'
              onClick={() => setIsOpen(false)}
              className='cursor-pointer fill-white opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
            />
          </div>
          <form className='flex w-full flex-col gap-5'>
            <div>
              <Controller
                name='name'
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      className='bg-gray900 text-[16px] font-bold'
                      type='text'
                      name='name'
                      placeholder='Name'
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )
                }}
              />
              {errors.name?.message && (
                <ErrorMessage message={errors.name.message} />
              )}
            </div>
            <div>
              <Controller
                name='description'
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      className='bg-gray900 text-[16px] font-bold'
                      type='text'
                      name='description'
                      placeholder='Description'
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )
                }}
              />
              {errors.description?.message && (
                <ErrorMessage message={errors.description.message} />
              )}
            </div>
            <div>
              <Controller
                name='permissionsIds'
                control={control}
                render={({ field }) => {
                  return (
                    <section className='flex flex-col gap-1'>
                      {data ? (
                        <>
                          {permissionGroups.map(group => (
                            <div
                              key={group.name}
                              className='flex flex-col gap-1'
                            >
                              <p className='text-[18px] font-bold text-white'>
                                {group.name}
                              </p>
                              <div className='flex flex-col gap-2'>
                                {group.permissions.map(permission => {
                                  const findPermission = data.find(
                                    perm => perm.slug === permission
                                  )

                                  if (!findPermission) return null

                                  return (
                                    <label
                                      key={findPermission.id}
                                      className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'
                                    >
                                      <p>{findPermission.description}</p>
                                      <Switch
                                        color='success'
                                        isSelected={field.value.includes(
                                          findPermission.id
                                        )}
                                        onValueChange={() => {
                                          if (
                                            field.value.includes(
                                              findPermission.id
                                            )
                                          ) {
                                            field.onChange(
                                              field.value.filter(
                                                perm =>
                                                  perm !== findPermission.id
                                              )
                                            )
                                          } else {
                                            field.onChange([
                                              ...field.value,
                                              findPermission.id
                                            ])
                                          }
                                        }}
                                      />
                                    </label>
                                  )
                                })}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No permissions found.</p>
                      )}
                    </section>
                  )
                }}
              />
            </div>

            <FormButton
              type='submit'
              title={initial ? 'Save' : 'Create'}
              stopPropagation
              preventDefault
              className='mt-[40px] bg-orange550 text-white'
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </div>
      </Drawer>
    </>
  )
}

export default CreateOrUpdateRole
