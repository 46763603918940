import { ILimits } from '@/types/account/limits.interface'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseLimits(data: any): ILimits {
  return {
    starter: {
      total_balance_usd: parseFloat(data.starter_total_balance_usd),
      send_onchain_usd: parseFloat(data.starter_send_onchain_usd),
      receive_onchain_usd: parseFloat(data.starter_receive_onchain_usd),
      send_lightning_usd: parseFloat(data.starter_send_lightning_usd),
      receive_lightning_usd: parseFloat(data.starter_receive_lightning_usd),
      daily_send_onchain_volume_usd: parseFloat(
        data.starter_daily_send_onchain_volume_usd
      ),
      daily_receive_onchain_volume_usd: parseFloat(
        data.starter_daily_receive_onchain_volume_usd
      ),
      daily_send_lightning_volume_usd: parseFloat(
        data.starter_daily_send_lightning_volume_usd
      ),
      daily_receive_lightning_volume_usd: parseFloat(
        data.starter_daily_receive_lightning_volume_usd
      )
    },
    exchange: {
      ach_deposit_transaction_usd: parseFloat(
        data.exchange_ach_deposit_transaction_usd
      ),
      ach_deposit_transaction_available_usd: parseFloat(
        data.exchange_ach_deposit_transaction_available_usd
      ),
      ach_deposit_monthly_limit_remaining_usd: parseFloat(
        data.exchange_ach_deposit_monthly_limit_remaining_usd
      ),
      ach_deposit_monthly_limit_usd: parseFloat(
        data.exchange_ach_deposit_monthly_limit_usd
      ),
      ach_withdrawal_transaction_usd: parseFloat(
        data.exchange_ach_withdrawal_transaction_usd
      ),
      ach_withdrawal_transaction_available_usd: parseFloat(
        data.exchange_ach_withdrawal_transaction_available_usd
      ),
      ach_withdrawal_monthly_limit_remaining_usd: parseFloat(
        data.exchange_ach_withdrawal_monthly_limit_remaining_usd
      ),
      ach_withdrawal_monthly_limit_usd: parseFloat(
        data.exchange_ach_withdrawal_monthly_limit_usd
      ),
      trade_usd: parseFloat(data.exchange_trade_usd),
      trade_daily_usd: parseFloat(data.exchange_trade_daily_usd),
      trade_daily_available_usd: parseFloat(
        data.exchange_trade_daily_available_usd
      ),
      lightning_deposit_transaction_usd: parseFloat(
        data.exchange_lightning_deposit_transaction_usd
      ),
      lightning_deposit_transaction_daily_usd: parseFloat(
        data.exchange_lightning_deposit_transaction_daily_usd
      ),
      lightning_deposit_transaction_daily_available_usd: parseFloat(
        data.exchange_lightning_deposit_transaction_daily_available_usd
      ),
      lightning_withdrawal_transaction_usd: parseFloat(
        data.exchange_lightning_withdrawal_transaction_usd
      ),
      lightning_withdrawal_transaction_daily_usd: parseFloat(
        data.exchange_lightning_withdrawal_transaction_daily_usd
      ),
      lightning_withdrawal_transaction_daily_available_usd: parseFloat(
        data.exchange_lightning_withdrawal_transaction_daily_available_usd
      ),
      onchain_send_usd: parseFloat(data.exchange_onchain_send_usd),
      onchain_send_daily_usd: parseFloat(data.exchange_onchain_send_daily_usd),
      onchain_send_daily_available_usd: parseFloat(
        data.exchange_onchain_send_daily_available_usd
      ),
      book_transfer_usd: parseFloat(data.exchange_book_transfer_usd),
      book_transfer_daily_usd: parseFloat(
        data.exchange_book_transfer_daily_usd
      ),
      book_transfer_daily_available_usd: parseFloat(
        data.exchange_book_transfer_daily_available_usd
      ),
      linkable_bank_accounts_available: parseInt(
        data.exchange_linkable_bank_accounts_available,
        10
      ),
      linkable_bank_accounts: parseInt(
        data.exchange_linkable_bank_accounts,
        10
      ),
      deposits_weekly: parseInt(data.exchange_deposits_weekly, 10),
      concurrent_transactions: parseInt(
        data.exchange_concurrent_transactions,
        10
      )
    }
  }
}
