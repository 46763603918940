export default function Notifications({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className={className}
    >
      <g id='Group_4114' data-name='Group 4114' transform='translate(-30 -357)'>
        <g
          id='Rectangle_7380'
          data-name='Rectangle 7380'
          transform='translate(30 357)'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
        >
          <rect width='24' height='24' rx='12' stroke='none' />
          <rect x='1' y='1' width='22' height='22' rx='11' fill='none' />
        </g>
        <rect
          id='Rectangle_7381'
          data-name='Rectangle 7381'
          width='12'
          height='12'
          rx='6'
          transform='translate(36 363)'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}
