export default function Rejected({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
    >
      <g
        id='Group_4112'
        data-name='Group 4112'
        transform='translate(-1020 -530)'
      >
        <rect
          id='Rectangle_5428'
          data-name='Rectangle 5428'
          width='20'
          height='20'
          rx='10'
          transform='translate(1020 530)'
          fill='#f74d1a'
        />
        <g
          id='Group_2900'
          data-name='Group 2900'
          transform='translate(656.691 483.506)'
        >
          <rect
            id='Rectangle_2283'
            data-name='Rectangle 2283'
            width='2.08'
            height='12.062'
            rx='1.04'
            transform='translate(376.838 51.494) rotate(45)'
            fill='#fff'
          />
          <rect
            id='Rectangle_2284'
            data-name='Rectangle 2284'
            width='2.08'
            height='12.062'
            rx='1.04'
            transform='translate(368.308 52.964) rotate(-45)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}
