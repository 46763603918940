import { ChevronLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

const BackButton = () => {
  const navigation = useNavigate()

  return (
    <button
      className='flex w-fit items-center gap-0.5 text-lg font-semibold lg:text-xl'
      onClick={() => {
        navigation(-1)
      }}
    >
      <ChevronLeft className='size-6' /> Go back
    </button>
  )
}

export default BackButton
