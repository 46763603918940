import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import MultipleAutocomplete from '@/components/MultipleAutocomplete'

import { ICreator } from '@/types/learn/creator.interface'

import api from '@/api'

interface Props {
  creators: string[]
  setCreators: (categories: string[]) => void
}

const CreatorsSelect: React.FC<Props> = ({ creators, setCreators }) => {
  const [search, setSearch] = useState<string>('')

  const { data, isLoading } = useQuery({
    queryKey: ['all-creators'],
    queryFn: async () => {
      const response = await api<ICreator[]>('admin/learn/creators', {
        method: 'GET',
        params: {
          isFullList: true
        }
      })
      if (response.data && response.data.length) {
        return response.data.map(item => ({
          label: item.name,
          value: item.id
        }))
      }
    }
  })

  return (
    <MultipleAutocomplete
      search={search}
      setSearch={setSearch}
      values={creators}
      setValues={setCreators}
      data={
        data
          ? data.filter(item =>
              item.label.toLowerCase().includes(search.toLowerCase())
            )
          : []
      }
      title='Select Creators'
      loading={isLoading}
    />
  )
}

export default CreatorsSelect
