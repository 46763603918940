import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import api from '@/api'
import Autocomplete from '@/components/Autocomplete'
import { ResultWithPagination } from '@/components/DataTable/DataTable.types'
import { IUser } from '@/types/account/user.interface'

interface Props {
  initialAccount?: IUser
  accountId?: string
  setAccountId: (accountId?: string) => void
}

const AccountSelect: React.FC<Props> = ({
  initialAccount,
  accountId,
  setAccountId
}) => {
  const [search, setSearch] = useState<string>('')

  const { data, isLoading } = useQuery({
    queryKey: ['users', search, initialAccount],
    queryFn: async () => {
      const response = await api<ResultWithPagination<IUser>>('admin/users', {
        method: 'POST',
        params: {
          search
        }
      })
      if (response.data.result && response.data.result.length) {
        const result = response.data.result.map(user => ({
          label: (
            <p className='flex min-w-full flex-row justify-between text-sm'>
              <span>@{user.username}</span>
              <span className='text-xs text-foreground/50'>{user.email}</span>
            </p>
          ),
          value: user.id
        }))
        if (
          initialAccount &&
          !result.find(user => user.value === initialAccount.id)
        ) {
          result.push({
            label: (
              <p className='flex flex-row justify-between'>
                <span>@{initialAccount.username}</span>
                <span className='text-sm text-foreground/50'>
                  {initialAccount.email}
                </span>
              </p>
            ),
            value: initialAccount.id
          })
        }
        return result
      }
    }
  })

  return (
    <Autocomplete
      disabled={initialAccount !== undefined}
      search={search}
      setSearch={setSearch}
      value={accountId}
      setValue={setAccountId}
      data={data || []}
      title='Select Account'
      loading={isLoading}
    />
  )
}

export default AccountSelect
