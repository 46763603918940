import styles from '@/components/Loader/loader.module.css'

import { cn } from '@/utils'

interface LoaderProps {
  type: 'absolute' | 'fixed'
  loading: boolean
  className?: string
}

export const Loader = ({ type, loading, className }: LoaderProps) => {
  return loading ? (
    <div
      className={cn(
        'left-0 top-0 z-1 flex h-full w-full items-center justify-center bg-gray800/10 backdrop-blur-sm',
        {
          absolute: type === 'absolute',
          fixed: type === 'fixed'
        },
        className
      )}
    >
      <div className={styles.loader} />
    </div>
  ) : null
}
