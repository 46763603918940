import React, { ReactNode, useEffect, useState } from 'react'

import styles from '@/components/Input/input.module.css'

import { cn } from '@/utils'

export interface InputProps {
  type: 'text' | 'email' | 'password'
  name: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  className?: string
  wrapperClassName?: string
  endContent?: ReactNode
  disabled?: boolean
  autoFocus?: boolean
}

export const Input = ({
  type,
  name,
  className,
  wrapperClassName,
  placeholder,
  onChange,
  endContent,
  value: propsValue,
  disabled,
  autoFocus
}: InputProps) => {
  const [value, setValue] = useState<string | undefined>(propsValue)

  useEffect(() => {
    setValue(propsValue)
  }, [propsValue])

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    onChange && onChange(e)
  }

  return (
    <div
      className={cn(
        'relative flex items-center justify-between overflow-hidden rounded-[10px]',
        wrapperClassName
      )}
    >
      <input
        type={type}
        name={name}
        className={cn(
          'w-full bg-gray800 p-[20px] outline-none',
          'rounded-[10px] border-2 border-gray500/20',
          'text-[16px] font-bold leading-[19px] text-white',
          {
            'text-white/20': disabled
          },
          styles.input,
          className
        )}
        placeholder={placeholder}
        onInput={inputOnChange}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {disabled && (
        <div className='absolute left-0 top-0 z-1 h-full w-full bg-white/10' />
      )}
      {endContent}
    </div>
  )
}
