import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import ProtectedComponent from '@/components/ProtectedComponent'

import CreateOrUpdateConfiguration from './Configurations/CreateOrUpdateConfiguration'
import CreateOrUpdateRole from '@/routes/Organization/Roles/CreateOrUpdateRole'
import Tabs, { tabs } from '@/routes/Organization/Tabs'
import CreateOrUpdateMember from '@/routes/Organization/TeamMembers/CreateOrEditMember'

export function Permissions() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!Object.keys(tabs).includes(location.pathname))
      navigate(Object.keys(tabs)[0], { replace: true })
  }, [location.pathname, navigate])

  return (
    <ProtectedComponent permissionSlug='organization_view' isPage>
      <Helmet>
        <title>{`STBA Admin - ${tabs[location.pathname]}`}</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white/15 pb-7 text-2xl font-bold text-white md:text-3xl'>
          {tabs[location.pathname]}
        </h1>
        <div className='flex w-full justify-between'>
          <Tabs />
          {location.pathname === '/organization/roles' && (
            <ProtectedComponent permissionSlug='roles_create'>
              <CreateOrUpdateRole />
            </ProtectedComponent>
          )}
          {location.pathname === '/organization/members' && (
            <ProtectedComponent permissionSlug='admins_create'>
              <CreateOrUpdateMember url='/admin/admins' />
            </ProtectedComponent>
          )}
          {location.pathname === '/organization/configurations' && (
            <ProtectedComponent permissionSlug='configurations_update'>
              <CreateOrUpdateConfiguration />
            </ProtectedComponent>
          )}
        </div>
        <Outlet />
      </section>
    </ProtectedComponent>
  )
}
