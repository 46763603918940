import { Helmet } from 'react-helmet'

import { DataTable } from '@/components/DataTable'
import ProtectedComponent from '@/components/ProtectedComponent'
import Tabs from '@/routes/Learn/Tabs'

import CreateOrUpdateVideo from '@/routes/Learn/Videos/CreateOrUpdateVideo'
import { columns } from '@/routes/Learn/Videos/columns'

const Videos: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>STBA Admin - Learn Videos</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white/15 pb-7 text-2xl font-bold text-white md:text-3xl'>
          Learn
        </h1>
        <div className='flex w-full justify-between'>
          <Tabs />
          <ProtectedComponent permissionSlug='learn_create'>
            <CreateOrUpdateVideo url='admin/learn/videos' />
          </ProtectedComponent>
        </div>
        <DataTable
          url='admin/learn/videos'
          columns={columns}
          totalText='videos'
          defaultSortBy='views'
          defaultSortOrder='desc'
        />
      </section>
    </>
  )
}

export default Videos
