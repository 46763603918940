import { Button } from '@/components/Button'

import { cn } from '@/utils'

interface ActionButtonProps {
  title: string
  onClick?: () => void
  className?: string
}

export const ActionButton = ({
  title,
  onClick,
  className
}: ActionButtonProps) => {
  return (
    <Button
      title={title}
      onClick={onClick}
      className={cn(
        'min-w-[82px] justify-center bg-orange500 text-center text-[12px] font-bold text-orange500 transition-all',
        className
      )}
    />
  )
}
