import { useEffect, useRef } from 'react'

import { cn } from '@/utils'

export default function Popover({
  children,
  target,
  offsetY = 0,
  visible,
  onClose
}: {
  children?: any
  target: string
  offsetY?: number
  visible: boolean
  onClose: () => void
}) {
  const popoverRef = useRef<any>()

  const updatePosition = () => {
    const targetEl = document.querySelector(target)
    if (!targetEl) {
      return
    }

    const popoverEl = popoverRef.current
    if (!popoverRef) {
      return
    }

    const targetRect = targetEl.getBoundingClientRect()
    const popoverRect = popoverEl.getBoundingClientRect()

    const x = targetRect.right - popoverRect.width
    const y = targetRect.bottom + offsetY

    popoverEl.style.transform = `translate3d(${x}px, ${y}px, 0px)`
  }

  const checkRectOverlap = (rect: any, mouse: any) => {
    return (
      mouse.x > rect.left &&
      mouse.x < rect.right &&
      mouse.y > rect.top &&
      mouse.y < rect.bottom
    )
  }

  const handleClickOutside = (e: any) => {
    const targetEl = document.querySelector(target)
    if (!targetEl) {
      return
    }

    const popoverEl = popoverRef.current
    if (!popoverRef) {
      return
    }

    const mouse = { x: e.clientX, y: e.clientY }
    const popoverRect = popoverEl.getBoundingClientRect()
    const targetRect = targetEl.getBoundingClientRect()
    const overlapsPopover = checkRectOverlap(popoverRect, mouse)
    const overlapsTarget = checkRectOverlap(targetRect, mouse)

    if (!overlapsPopover && !overlapsTarget) {
      onClose()
    }
  }

  useEffect(() => {
    updatePosition()

    window.addEventListener('resize', updatePosition)
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('resize', updatePosition)
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [target, popoverRef])

  return (
    <div
      ref={popoverRef}
      className={cn(
        'absolute left-0 top-0 min-w-[200px] overflow-hidden rounded border border-zinc-800 bg-zinc-900 text-white',
        {
          'pointer-events-auto visible': visible,
          'pointer-events-none invisible': !visible
        }
      )}
    >
      {children}
    </div>
  )
}
