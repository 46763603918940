import { Helmet } from 'react-helmet'

import { DataTable } from '@/components/DataTable'
import ProtectedComponent from '@/components/ProtectedComponent'

import { SendNotification } from '@/routes/Notifications/SendNotification'
import { columns } from '@/routes/Notifications/columns'

export function Notifications() {
  return (
    <>
      <Helmet>
        <title>STBA Admin - Notifications</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <section className='flex w-full flex-wrap justify-between border-b border-white/15 pb-7'>
          <h1 className='text-2xl font-bold text-white md:text-3xl'>
            Notifications
          </h1>
          <ProtectedComponent permissionSlug='users_send_notifications'>
            <SendNotification />
          </ProtectedComponent>
        </section>
        <DataTable
          url='/admin/notifications'
          columns={columns}
          totalText='notifications'
          tableClassName='md:!max-h-[calc(100vh-550px)]'
        />
      </section>
    </>
  )
}
