import { createSlice } from '@reduxjs/toolkit'

import { tokens } from '@/constants/cookies'
import { IAdmin } from '@/types/account/admin.interface'
import { ILoginResponse } from '@/types/auth/login.interface'
import { IPermission } from '@/types/roles/permission.inteface'

const initialState: {
  admin: IAdmin | null
  isAuthenticated: boolean
  isSuperuser: boolean
  permissions: IPermission[]
} = {
  admin: null,
  isAuthenticated: false,
  isSuperuser: false,
  permissions: []
}

export const auth = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login(
      state,
      action: {
        payload: ILoginResponse
      }
    ) {
      state.admin = action.payload.admin
      state.isAuthenticated = true
      state.isSuperuser = action.payload.admin.role?.is_super_role || false
      state.permissions = action.payload.admin.role?.permissions || []

      localStorage.setItem(tokens.accessToken, action.payload.token)
    },
    logout(state) {
      state.admin = null
      state.isAuthenticated = false
      state.isSuperuser = false
      state.permissions = []

      localStorage.removeItem(tokens.accessToken)
    },
    updateAdmin(
      state,
      action: {
        payload: IAdmin
      }
    ) {
      state.admin = action.payload
      state.isSuperuser = action.payload.role?.is_super_role || false
      state.permissions = action.payload.role?.permissions || []
    },
    updateToken(
      _,
      action: {
        payload: {
          token: string
        }
      }
    ) {
      localStorage.setItem(tokens.accessToken, action.payload.token)
    }
  }
})

export const authActions = auth.actions

export default auth.reducer
