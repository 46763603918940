import { cn } from '@/utils'

export default function ProgressBar({
  value,
  color,
  className
}: {
  value: number
  color?: string
  className?: string
}) {
  const progress = value * 100
  return (
    <div
      className={cn(
        'relative h-10 w-full overflow-hidden rounded-full border-2 border-foreground/50 bg-foreground/5',
        className
      )}
    >
      <div
        className={cn('h-full w-full bg-primary/50', {
          [`bg-${color}`]: color
        })}
        style={{
          transform: `translateX(-${100 - Math.round(progress)}%)`
        }}
      />
      <div className='absolute inset-0 flex items-center justify-end px-2'>
        <span className='text-sm font-bold text-foreground/50'>
          {progress.toFixed(2)}%
        </span>
      </div>
    </div>
  )
}
