export default function Close({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
			fill='currentColor'
			className={className}
    >
      <g
        id='Group_843'
        data-name='Group 843'
        transform='translate(-368.308 -51.494)'
      >
        <rect
          id='Rectangle_2283'
          data-name='Rectangle 2283'
          width='4.159'
          height='24.124'
          rx='2.08'
          transform='translate(385.367 51.494) rotate(45)'
        />
        <rect
          id='Rectangle_2284'
          data-name='Rectangle 2284'
          width='4.159'
          height='24.124'
          rx='2.08'
          transform='translate(368.309 54.435) rotate(-45)'
        />
      </g>
    </svg>
  )
}
