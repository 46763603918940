import axios from 'axios'

import { tokens } from '@/constants/cookies'

import { apiConfig } from '@/api/config'

const getAccessToken = (): string | null => {
  return localStorage.getItem(tokens.accessToken)
}

const api = axios.create(apiConfig)

api.interceptors.request.use(
  config => {
    const accessToken = getAccessToken()

    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : ''

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response) {
      const logout = () => {
        localStorage.removeItem(tokens.accessToken)
        window.location.href = '/login'
      }

      const { status } = error.response
      const originalRequest = error.config

      if (status === 401) {
        // Avoid retrying the refresh if it has already failed
        if (originalRequest.url.includes('/admin/auth/update-token')) {
          // Logout when refresh token fails with 401
          logout()
          return Promise.reject(error)
        }

        if (!originalRequest._retry) {
          originalRequest._retry = true
          try {
            const resp = await api.post('/admin/auth/update-token')
            if (resp.status === 200) {
              localStorage.setItem(tokens.accessToken, resp.data.token)
              originalRequest.headers.Authorization = `Bearer ${resp.data.token}`
              return api(originalRequest) // Retry the original request
            }
          } catch (refreshError) {
            // If refreshing the token fails, logout
            logout()
          }
        }
      } else if (status === 403) {
        alert("You don't have permission to access this resource.")
      } else if (status === 429) {
        if (typeof window !== 'undefined') {
          alert('Too many requests. Please try again later.')
        }
      }
    }
    return Promise.reject(error)
  }
)

export default api
