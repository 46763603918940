import { useMutation, useQueryClient } from '@tanstack/react-query'

import api from '@/api'
import { DeleteButton } from '@/components/Buttons/DeleteButton'

interface Props {
  id: string
}

const DeleteAccountGroup: React.FC<Props> = ({ id }) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: ['deleteAccountGroup'],
    mutationFn: async (id: string) => {
      await api.delete(`/admin/account-groups/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
    }
  })

  return (
    <>
      <DeleteButton onDelete={() => mutate(id)} title='Delete' />
    </>
  )
}

export default DeleteAccountGroup
