import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'

import api from '@/api'
import { DataTable } from '@/components/DataTable'
import { buttonVariants } from '@/components/DataTable/Button'
import { Loader } from '@/components/Loader'
import ProtectedComponent from '@/components/ProtectedComponent'
import { IAccountGroup } from '@/types/account-groups/account-group.interface'

import { AddToGroup } from '@/routes/AccountGroups/ByID/AddToGroup'
import { columns } from '@/routes/AccountGroups/ByID/columns'

const AccountGroupById = () => {
  const { id } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: [`/admin/account-groups/${id}`],
    queryFn: async () => {
      const resp = await api.get<IAccountGroup>(`/admin/account-groups/${id}`)

      return resp.data
    }
  })

  return (
    <ProtectedComponent permissionSlug='account_groups_view' isPage>
      <Helmet>
        <title>STBA Admin - Account Group</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        {isLoading ? (
          <Loader loading type='absolute' />
        ) : (
          <>
            <section className='flex w-full flex-wrap justify-between border-b border-white/15 pb-7'>
              <h1 className='text-2xl font-bold text-white md:text-3xl'>
                Account Group - {data?.name}
              </h1>
              <div className='flex flex-col gap-2 md:flex-row'>
                <Link
                  to='/account-groups'
                  className={buttonVariants({
                    variant: 'secondary',
                    size: 'lg',
                    rounded: 'full'
                  })}
                >
                  Back
                </Link>
                <ProtectedComponent permissionSlug='account_groups_update'>
                  <AddToGroup groupId={id || ''} />
                </ProtectedComponent>
              </div>
            </section>
            <DataTable
              url={`/admin/account-groups/${id}/accounts`}
              columns={columns}
              totalText='accounts'
            />
          </>
        )}
      </section>
    </ProtectedComponent>
  )
}

export default AccountGroupById
