export default function Metrics({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
			className={className}
    >
      <g id='Group_4103' data-name='Group 4103' transform='translate(-30 -165)'>
        <rect
          id='Rectangle_7224'
          data-name='Rectangle 7224'
          width='6'
          height='12'
          rx='3'
          transform='translate(30 177)'
        />
        <rect
          id='Rectangle_7225'
          data-name='Rectangle 7225'
          width='6'
          height='18'
          rx='3'
          transform='translate(39 171)'
        />
        <rect
          id='Rectangle_7226'
          data-name='Rectangle 7226'
          width='6'
          height='24'
          rx='3'
          transform='translate(48 165)'
        />
      </g>
    </svg>
  )
}
