export default function Earn({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='currentColor'
      className={className}
    >
      <path
        id='Polygon_196'
        data-name='Polygon 196'
        d='M11.12,1.635a1,1,0,0,1,1.761,0l3.177,5.9a1,1,0,0,0,.406.406l5.9,3.177a1,1,0,0,1,0,1.761l-5.9,3.177a1,1,0,0,0-.406.406l-3.177,5.9a1,1,0,0,1-1.761,0l-3.177-5.9a1,1,0,0,0-.406-.406l-5.9-3.177a1,1,0,0,1,0-1.761l5.9-3.177a1,1,0,0,0,.406-.406Z'
      />
    </svg>
  )
}
