export default function Learn({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.858'
      height='24'
      viewBox='0 0 18.858 24'
      fill='currentColor'
      className={className}
    >
      <g id='Group_4115' data-name='Group 4115' transform='translate(-34 -293)'>
        <path
          id='Polygon_159'
          data-name='Polygon 159'
          d='M10.313,2.652a2,2,0,0,1,3.375,0l8.357,13.133a2,2,0,0,1-1.687,3.074H3.643a2,2,0,0,1-1.687-3.074Z'
          transform='translate(52.858 293) rotate(90)'
        />
      </g>
    </svg>
  )
}
