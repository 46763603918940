import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/DataTable/ColumnHeader'
import Icon from '@/components/Icon'
import ProtectedComponent from '@/components/ProtectedComponent'

import { IAccessCode } from '@/types/access-code/access-code.interface'

import formatId from '@/utils/formatId'

import UserDetailsModal from '../../components/UserDetails/Modal'

import ToggleIsSent from './ToggleIsSent'

export const columns: ColumnDef<IAccessCode>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Code' />
    },
    cell: ({ row }) => {
      return <p>{row.original.code}</p>
    }
  },
  {
    accessorKey: 'is_sent',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Sent' />
    },
    cell: ({ row }) => {
      if (!row.original.is_sent) {
        return (
          <div className='flex flex-row items-center gap-2'>
            <Icon icon='Approved' className='size-4' />
            Not Sent
          </div>
        )
      }
      return (
        <div className='flex flex-row items-center gap-2'>
          <Icon icon='Rejected' className='size-4' />
          Sent
        </div>
      )
    }
  },
  {
    accessorKey: 'is_claimed',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Claimed' />
    },
    cell: ({ row }) => {
      if (!row.original.is_claimed) {
        return (
          <div className='flex flex-row items-center gap-2'>
            <Icon icon='Approved' className='size-4' />
            Unclaimed
          </div>
        )
      }
      return (
        <div className='flex flex-row items-center gap-2'>
          <Icon icon='Rejected' className='size-4' />
          Claimed
        </div>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      if (row.original.account) {
        return <UserDetailsModal fromOtherPage user={row.original.account} />
      }
      return <p className='text-foreground/50'>Not Claimed</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Date Joined' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {format(row.original.created_at, 'yyyy.MM.dd')}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <ProtectedComponent permissionSlug='access_codes_create'>
            <ToggleIsSent id={row.original.id} isSent={row.original.is_sent} />
          </ProtectedComponent>
        </div>
      )
    },
    enableSorting: false
  }
]
