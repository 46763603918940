import { useQuery } from '@tanstack/react-query'

import { Section } from '@/components/InfoSection'
import { SubDescriptionSectionItem } from '@/components/InfoSection/SectionItem/SubDescriptionSectionItem'

import { IReferMetricsData } from '@/types/metrics/metrics.interface'

import api from '@/api'
import styles from '@/routes/Metrics/metrics.module.css'
import { cn, msatsToSats, msatsToUsd } from '@/utils'

const ReferMetrics: React.FC = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['referMetrics'],
    queryFn: async () =>
      await api.get<IReferMetricsData>('/admin/refer/metrics'),
    enabled: true,
    select: res => res.data
  })

  return (
    <Section title=''>
      <SubDescriptionSectionItem
        labelClassName='!text-purple400'
        label={`$${msatsToUsd(
          data?.ibex_balance_msats,
          data?.btc_price,
          '-'
        ).toString()}`}
        description={`${msatsToSats(data?.ibex_balance_msats, '-').toString()} sats`}
        subDescription={'IBEX Balance'}
        className={cn('w-full min-w-0 flex-none', styles.sectionItemColumnFour)}
        loading={isLoading}
        error={isError ? 'Error loading data' : undefined}
      />
      <SubDescriptionSectionItem
        labelClassName='!text-purple400'
        label={`$${msatsToUsd(
          data?.zh_balance_msats,
          data?.btc_price,
          '-'
        ).toString()}`}
        description={`${msatsToSats(data?.zh_balance_msats, '-').toString()} sats`}
        subDescription={'Zero Hash Balance'}
        className={cn('w-full min-w-0 flex-none', styles.sectionItemColumnFour)}
        loading={isLoading}
        error={isError ? 'Error loading data' : undefined}
      />
      <SubDescriptionSectionItem
        labelClassName='!text-orange500'
        label={`$${msatsToUsd(
          data?.unclaimed_msats,
          data?.btc_price,
          '-'
        ).toString()}`}
        description={`${msatsToSats(String(data?.unclaimed_msats), '-').toString()} sats`}
        subDescription={'Unclaimed Earnings'}
        className={cn('w-full min-w-0 flex-none', styles.sectionItemColumnFour)}
        loading={isLoading}
        error={isError ? 'Error loading data' : undefined}
      />
      <SubDescriptionSectionItem
        labelClassName='!text-orange550'
        label={`$${msatsToUsd(
          data?.claimed_msats,
          data?.btc_price,
          '-'
        ).toString()}`}
        description={`${msatsToSats(String(data?.claimed_msats), '-').toString()} sats`}
        subDescription={'Total Paid Out'}
        className={cn('w-full min-w-0 flex-none', styles.sectionItemColumnFour)}
        loading={isLoading}
        error={isError ? 'Error loading data' : undefined}
      />
    </Section>
  )
}

export default ReferMetrics
