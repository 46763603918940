import { IBankFunding } from '../bank-fundings/bank-fundings.interface'
import { IBankTrade } from '../bank-trade/bank-trade.interface'
import { IBankTransfer } from '../bank-transfer/bank-transfer.interface'
import { ILightningTransaction } from '../transaction-lightning/transaction-lightning.interface'
import { IOnchainTransaction } from '../transaction-onchain/transaction-onchain.interface'

import { IipAddress } from './ip-address.interface'
import { ILimits } from './limits.interface'
import { IReferral } from './referral.interface'

export enum KycStatusEnum {
  UNVERIFIED = 'UNVERIFIED',
  STARTED = 'STARTED',
  VERIFIED = 'VERIFIED',
  VERIFIED_NO_SSN = 'VERIFIED_NO_SSN',
  FAILED = 'FAILED'
}

export interface IUser {
  id: string
  created_at: string
  updated_at: string
  first_name: string
  last_name: string
  username: string
  display_name: string
  email: string
  otp: string
  avatar: string | null
  last_login_at: string
  status: string
  membership_type: 'REGULAR' | 'SATOSHI_PLUS'
  fcm_token: string
  device_id: string
  ibex_account_id: string
  ibex_address_onchain: string
  ibex_address_lightning: string
  ibex_address_lightning_long: string
  is_notifications_enabled: boolean
  is_tracking_enabled: boolean
  is_kyc_blocked: boolean
  is_two_factor_enabled: boolean
  accrued_balance_msats: string
  daily_earn_rate_msats: string
  earn_started_at: string
  streak_count: number
  streak_started_at: string | null
  last_claimed_at: string
  earnings_msats: string
  earned_unclaimed_msats: string
  earned_unclaimed_updated_at: string | null
  secret: string
  kyc_status: KycStatusEnum
  kyc_failure_codes?: string[]
  is_deleted: boolean
  blocked: boolean
  is_all_ip_addresses_blocked: boolean
  ip_addresses: IipAddress[]
  withdrawals_paused: boolean
  rank: number
  rank_updated_at: Date

  date_of_birth?: string
  phone_number?: string
  address_street?: string
  address_street_2?: string
  address_city?: string
  address_subdivision?: string
  address_postal_code?: string
  address_country_code?: string

  app_version?: string
  device_type?: string

  referral_code: string
  referrals: IReferral[]
  refer_earnings_msats: string
  refer_claimable_msats: string

  starter_stats: {
    balance_msats: string
    total_received_msats: string
    total_sent_msats: string
  }
  exchange_stats: {
    balance_usd: string
    balance_btc: string
    total_send_usd: string
    total_received_usd: string
    total_bought_msats: string
    total_sold_msats: string
  }
  limits: ILimits
  transactions: {
    starter: {
      onchain: IOnchainTransaction[]
      lightning: ILightningTransaction[]
    }
    exchange: {
      trades: IBankTrade[]
      fundings: IBankFunding[]
      transfers: IBankTransfer[]
    }
  }
}
