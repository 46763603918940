import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import { EditButton } from '@/components/Buttons/EditButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { Modal } from '@/components/Modal'

import {
  ICreateOrUpdateUsernameBlacklist,
  IUsernameBlacklist
} from '@/types/username-blacklist/username-blacklist.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: IUsernameBlacklist
}

const CreateOrUpdate: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateUsernameBlacklist>({
    mode: 'onChange',
    defaultValues: initial || {
      username: ''
    }
  })

  useEffect(() => {
    reset(
      initial || {
        username: ''
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-category' : 'add-category'],
    mutationFn: (data: ICreateOrUpdateUsernameBlacklist) =>
      api(
        initial
          ? `/admin/username-blacklist/${initial.id}`
          : '/admin/username-blacklist',
        {
          method: initial ? 'PATCH' : 'POST',
          data
        }
      ),
    onSuccess: () => {
      toast.success(
        `Username Blaclist item ${initial ? 'updated' : 'created'} successfully!`
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `Failed to ${initial ? 'update' : 'create'} Username Blaclist item`
      )
    }
  })

  const onSubmit: SubmitHandler<
    ICreateOrUpdateUsernameBlacklist
  > = async data => mutate(data)

  return (
    <>
      {initial ? (
        <EditButton onClick={() => setIsOpen(true)} title='Edit' />
      ) : (
        <AddButton title='Add Username' onClick={() => setIsOpen(true)} />
      )}
      <Modal visible={isOpen} onClose={() => setIsOpen(false)}>
        <Loader type='absolute' loading={isPending} />
        <Modal.Header
          title={initial ? 'Edit Username' : 'Add Username to Blacklist'}
          closable
        />
        <Modal.Content>
          <form className='mt-4 flex flex-col gap-5'>
            <Field
              placeholder='username'
              {...register('username', {
                required: 'Username is required',
                minLength: {
                  value: 3,
                  message: 'Username must be at least 3 characters'
                }
              })}
              error={errors.username?.message}
            />

            <FormButton
              onClick={handleSubmit(onSubmit)}
              preventDefault
              stopPropagation
              title='Confirm'
              className='mt-2 bg-orange550 text-white'
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default CreateOrUpdate
