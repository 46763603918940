import { Helmet } from 'react-helmet'

import { DataTable } from '@/components/DataTable'
import ProtectedComponent from '@/components/ProtectedComponent'

import { columns } from '@/routes/KYCVerifications/columns'

const BankVerifications: React.FC = () => {
  return (
    <ProtectedComponent permissionSlug='kyc_verifications_view' isPage>
      <Helmet>
        <title>STBA Admin - Bank Verifications</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <h1 className='border-b border-white/15 pb-7 text-2xl font-bold text-white md:text-3xl'>
          KYC verifications
        </h1>
        <DataTable url='admin/bank/verifications' columns={columns} />
      </section>
    </ProtectedComponent>
  )
}

export default BankVerifications
