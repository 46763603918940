export default function User({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27.6'
      height='24'
      viewBox='0 0 27.6 24'
      className={className}
    >
      <g
        id='Group_4066'
        data-name='Group 4066'
        transform='translate(-1879 -105)'
        fill='currentColor'
      >
        <path
          id='Rectangle_7297'
          data-name='Rectangle 7297'
          d='M8.64,0H18.96A8.64,8.64,0,0,1,27.6,8.64v0a2.16,2.16,0,0,1-2.16,2.16H2.16A2.16,2.16,0,0,1,0,8.64v0A8.64,8.64,0,0,1,8.64,0Z'
          transform='translate(1879 118.2)'
        />
        <rect
          id='Rectangle_7296'
          data-name='Rectangle 7296'
          width='10.8'
          height='10.8'
          rx='5.4'
          transform='translate(1887.4 105)'
        />
      </g>
    </svg>
  )
}
