export default function InProgress({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
    >
      <g
        id='Group_4135'
        data-name='Group 4135'
        transform='translate(-1283 -683)'
      >
        <g id='Group_4134' data-name='Group 4134' transform='translate(263 85)'>
          <g
            id='Group_2198'
            data-name='Group 2198'
            transform='translate(1020 598)'
          >
            <rect
              id='BTCB'
              width='20'
              height='20'
              rx='10'
              transform='translate(0 0)'
              fill='#55b0fe'
            />
          </g>
        </g>
        <text
          id='i'
          transform='translate(1293 698)'
          fill='#fff'
          fontSize='13'
          fontFamily='Montserrat-Bold, Montserrat'
          fontWeight='700'
        >
          <tspan x='-1' y='0'>
            i
          </tspan>
        </text>
      </g>
    </svg>
  )
}
